/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useMoralis } from "react-moralis";
import { getEllipsisTxt, NFT, WalletModal } from "@web3uikit/web3";
import { CopyButton } from "@web3uikit/core";
import PFP from "../Profile/PFP";
import LogOutModal from "../Modals/LogOutModal";
import SettingsModal from "../SettingsModal/SettingsModal";
import { getPolygonLogo } from "../../helpers/formatters";


interface WalletDrawerProps {
  isVisible: boolean,
  close: () => void
}


const WalletDrawer: React.FC<WalletDrawerProps> = ({ isVisible, close }) => {

  const [isConnectModalVisible, setIsConnectModalVisible] = useState<boolean>(false);
  const openConnectModal = () => setIsConnectModalVisible(true);
  const closeConnectModal = () => setIsConnectModalVisible(false);

  const [isLogOutModalVisible, setIsLogOutModalVisible] = useState<boolean>(false);
  const openLogOutModal = () => setIsLogOutModalVisible(true);
  const closeLogOutModal = () => setIsLogOutModalVisible(false);

  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState<boolean>(false);
  const openSettingsModal = () => setIsSettingsModalVisible(true);
  const closeSettingsModal = () => setIsSettingsModalVisible(false);

  const [userDetails, setUserDetails] = useState<any>();

  const { user, Moralis } = useMoralis();

  const getUserDetails = async() => {
    if(user) {
      try{
        const response: any = await Moralis.Cloud.run("getUserBalancesAndDomain", { address: user.get("ethAddress"), chain: "0xa869" });
        setUserDetails(response);
        console.log(response);
      }catch(err: any){
        console.log(err);
      }
    }
  };

  const getTokenLogo = (tokenLogo: any) => {
    if(!tokenLogo) return "https://www.pngitem.com/pimgs/m/77-775920_ask-a-question-mark-2-number-in-circle.png";
    else return tokenLogo;
  };

  useEffect(() => {
    getUserDetails();
  }, []);
  
  useEffect(() => {
    console.log(userDetails);
  }, [userDetails]);

  useEffect(() => {
    getUserDetails();
  }, [user]);

  const renderAddress = () => {
    if(userDetails) if(userDetails.domain) return `${userDetails.domain} (${getEllipsisTxt(user?.get("ethAddress"), 5)})`;
    else return `${getEllipsisTxt(user?.get("ethAddress"), 6)}`;
  };

  const getNFTImage = (metadata: string) => {
    const obj = JSON.parse(metadata);
    if(obj) return `https://gateway.moralisipfs.com/ipfs/${obj.image.slice(-46)}/`;
    else return undefined;
  };

  return (
    <>
      <Transition.Root show={isVisible} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-3xl">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-0 -ml-8 flex pt-6 pr-16 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={close}
                        >
                          <span className="sr-only">Close panel</span>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-8 w-8">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white pb-6 shadow-xl">
                      <div className="px-4 sm:px-6 flex flex-row justify-between sticky top-0 z-10 py-6 bg-white">
                        <Dialog.Title className="text-4xl font-extrabold text-gray-900">My Wallet:</Dialog.Title>
                        {user ? (
                          <button
                            onClick={openLogOutModal}
                            className='ml-8 mt-1 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-lg shadow-md text-base font-bold text-white bg-indigo-500 hover:bg-indigo-600 active:bg-indigo-700'
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                            </svg>
                          Log Out
                          </button>
                        ):null}
                      </div>
                      <div className="relative mt-4 flex-1 px-4 sm:px-6">
                        {user ? (
                          <div>
                            <div>
                              <div className='mb-7 flex flex-row justify-between'>
                                <div className='flex flex-row'>
                                  <PFP
                                    scale={6.5}
                                    stylesBlockie="rounded-full border-4 border-gray-300 transition-all duration-300"
                                    stylesImg="rounded-full border-4 border-gray-300 transition-all duration-300 w-14 h-14"
                                  />
                                  <div className='ml-4'>
                                    <p className="block text-sm font-medium text-gray-700">Username:</p>
                                    <h2 className="text-2xl font-bold align-text-bottom">{user.get("username")}</h2>
                                  </div>
                                </div>
                                <div className='flex flex-row'>
                                  <div onClick={openSettingsModal}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-gray-500 ml-8 mt-2 hover:text-gray-800 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div className='pb-8'>
                                <div className='flex flex-row justify-between pl-2 mb-8'>
                                  <div className='flex flex-row'>
                                    <img
                                      src={getPolygonLogo()}
                                      className="rounded-full w-6 h-6 mr-1"
                                      alt="MATIC Logo"
                                    />
                                    <p className='text-gray-700 font-medium text-xl mr-3 cursor-pointer px-2 rounded-full hover:bg-indigo-50 transition-all duration-300'><a href={`https://mumbai.polygonscan.com/address/${user?.get("ethAddress")}`} target="_blank" rel="noreferrer">{renderAddress()}</a></p>
                                    <CopyButton
                                      text={user.get("ethAddress")}
                                      revertIn={6500}
                                    />
                                  </div>
                                </div>
                                <hr />
                              </div>
                            </div>
                            <div>
                              <p className='font-extrabold text-3xl mb-6 ml-2'>My Balances:</p>
                              <div className='flex flex-row justify-between bg-indigo-50 p-6 rounded-3xl mb-8'>
                                {/**native balance */}
                                <div className='flex flex-row'>
                                  <img
                                    src={getPolygonLogo()}
                                    className="rounded-full w-7 h-7 mr-4"
                                    alt="MATIC Logo"
                                  />
                                  <p className='text-xl font-normal'>Polygon <span className='font-medium ml-1'>MATIC</span></p>
                                </div>
                                <div className='flex flex-row'>
                                  <p className='text-xl font-normal'><span className='text-lg font-medium mr-1'>{userDetails?.nativeBalance}</span> MATIC</p>
                                </div>
                              </div>
                              <div className='bg-indigo-50 p-6 rounded-3xl mb-8'>
                                <p className='font-extrabold text-2xl text-gray-800'>All my Tokens:</p>
                                {userDetails ? (
                                  <>
                                    {userDetails.tokens.map((token: any, index: number) => {
                                      return(
                                        <div key={index} className="flex flex-row justify-between mb-2">
                                          <div className='flex flex-row'>
                                            <img
                                              src={getTokenLogo(token.logo)}
                                              className="rounded-full w-7 h-7 mr-4"
                                              alt="Token Logo"
                                            />
                                            <p className='text-xl font-normal'>{token.name} <span className='font-medium ml-1'>{token.symbol}</span></p>
                                          </div>
                                          <div className='flex flex-row'>
                                            <p className='text-xl font-normal'><span className='text-lg font-medium mr-1'>{token.balance}</span> {token.symbol}</p>
                                          </div>
                                        </div>
                                      );
                                    })}
                                    {userDetails.tokens.length==0 ? (
                                      <p className='text-xl font-extrabold text-center'>You don&apos;t have any tokens.</p>
                                    ):null}
                                  </>
                                ):(
                                  <p className='text-xl font-extrabold text-center'>Loading . . .</p>
                                )}
                              </div>
                              <div className='bg-indigo-50 p-6 rounded-3xl mb-10'>
                                {/**nft balances */}
                                <p className='font-extrabold text-2xl text-gray-800 mb-5'>All my NFTs:</p>
                                {userDetails ? (
                                  <div className='grid grid-cols-3 gap-6'>
                                    {userDetails.nfts.map((nft: any, index: number) => {
                                      return(
                                        <a key={index} target="_blank" href={`https://mumbai.polygonscan.com/token/${nft.token_address}?a=${nft.token_id}`} rel="noreferrer">
                                          <div className="bg-white rounded-xl transform cursor-pointer hover:-translate-y-2 transition duration-500 ease-in-out">
                                            <img
                                              src={getNFTImage(nft.metadata) ? getNFTImage(nft.metadata) : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTH4d1ldfiI0npUaDbUXnRHjumUJmSpIaKErA&usqp=CAU"}
                                              alt="NFT Image"
                                              className='max-h-332	rounded-xl'
                                            />
                                            <div className='p-2'>
                                              <p className='font-medium font-xl'>{nft.name}</p>
                                              <p className='font-light font-md'>{nft.contract_type}</p>
                                            </div>
                                          </div>
                                        </a>
                                      );
                                    })}
                                  </div>
                                ):(
                                  <p className='text-xl font-extrabold text-center'>Loading . . .</p>
                                )}
                                {userDetails?.nfts.length==0 ? (
                                  <p className='text-xl font-extrabold text-center'>You don&apos;t have any NFTs.</p>
                                ):null}
                              </div>
                            </div>
                          </div>
                        ):(
                          <div className='mt-24 items-center text-center'>
                            <p className='text-2xl font-normal text-center mb-6'>You need to connect your wallet:</p>
                            <button
                              onClick={openConnectModal}
                              className='px-4 py-2 border border-transparent rounded-lg shadow-md text-base font-bold text-white bg-indigo-500 hover:bg-indigo-600 active:bg-indigo-700'
                            >Connect Wallet</button>
                          </div>
                        )}
                        {/* /End replace */}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <WalletModal
        isOpened={isConnectModalVisible}
        setIsOpened={setIsConnectModalVisible}
      />

      <LogOutModal
        isVisible={isLogOutModalVisible}
        close={closeLogOutModal}
      />

      <SettingsModal
        isVisible={isSettingsModalVisible}
        close={closeSettingsModal}
      />

    </>
  );
};

export default WalletDrawer;