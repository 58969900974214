import React, { useEffect, useState } from "react";
import { Loading, useNotification } from "@web3uikit/core";
import { useDropzone } from "react-dropzone";
import { useMoralisFile } from "react-moralis";
import { getNotification } from "../../helpers/formatters";
import TemplatesModal from "../Templates/TemplatesModal";

interface ImageInputProps {
  image: string,
  setImage: any,
  showTemplates?: boolean
}

const ImageInput: React.FC<ImageInputProps> = ({ image="", setImage, showTemplates=true }) => {

  const [isTemplatesModalVisible, setIsTemplatesModalVisible] = useState<boolean>(false);
  const openTemplatesModal = () => setIsTemplatesModalVisible(true);
  const closeTemplatesModal = () => setIsTemplatesModalVisible(false);

  const { error, isUploading, moralisFile, saveFile } = useMoralisFile();
  const notify = useNotification();
  const [files, setFiles] = useState<any>([]);

  const {getRootProps, getInputProps} = useDropzone({
    maxFiles: 1,
    onDrop: (acceptedFiles: any) => {
      console.log(acceptedFiles);
      setFiles(
        acceptedFiles.map((file: any) => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
      );
    }
  });

  const thumbs = () => {
    if(isUploading) {
      return(
        <div className='flex items-center justify-center align-middle h-full my-32'>
          <Loading
            spinnerColor="rgba(156, 163, 175, 1)"
            text="Uploading Image . . ."
            size={40}
            fontSize={16}
          />
        </div>
      );
    }else{
      return(
        <div className="image-outer-box p-3">
          <img
            src={image}
            className="h-full w-full rounded-lg"
            alt="Uploaded Image"
          />
          <p className="text-gray-600 font-light text-sm mt-2">* Click or drag and drop on the image to change it.</p>
        </div>
      );
    }
  };

  const select = (image: string) => {
    setImage(image);
    closeTemplatesModal();
  };

  useEffect(() => {
    async function uploadToIPFS(img: any) {
      await saveFile("coin-logo.jpeg", img, { saveIPFS: true });
    }
    uploadToIPFS(files[0]);
  }, [files]);

  useEffect(() => {
    if(moralisFile != undefined && setImage){
      console.log(moralisFile.ipfs());
      setImage(moralisFile.ipfs());
      notify(getNotification("success", "Uploaded Successfuly! 😀", "Image was uploaded successfuly!"));
    }
  }, [moralisFile]);
  
  useEffect(() => {
    if(error && files.length > 0) notify(getNotification("error", "Something Went Wrong! 😥", error.message));
  }, [error]);

  useEffect(() => {
    if(isUploading) notify(getNotification("info", "Uploading . . . ⌛", "Image is uploading, please wait!"));
  }, [isUploading]);

  return (
    <>    
      <div className={"h-fit min-h-full max-w-md mr-8"}>
        <div className={`h-fit min-h-full mb-2 border-2 ${isUploading ? "border-indigo-500" : "border-gray-300"} rounded-xl`}>
          <div {...getRootProps()} >
            <input {...getInputProps()} className="huge-box"/>
            {files.length==0 && image.length==0 ? (
              <div className='mr-auto ml-auto my-32 h-full'>
                <svg xmlns="http://www.w3.org/2000/svg" className="mr-auto ml-auto h-12 w-12 text-gray-400 text-center" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
                <p className='text-lg font-light text-gray-500 text-center'>Click or drag and drop to upload.</p>
              </div>
            ):(
              <div>
                {thumbs()}
              </div>
            )}
          </div>
        </div>
        {showTemplates ? (
          <p className="text-gray-600 font-light text-lg">Don&apos;t have an image? Browse our <span className='text-indigo-600 font-normal cursor-pointer' onClick={openTemplatesModal}>templates</span>.</p>
        ):null}
      </div>

      <TemplatesModal
        isVisible={isTemplatesModalVisible}
        close={closeTemplatesModal}
        select={select}
      />
    </>
  );
};

export default ImageInput;