import React from "react";

interface TextAreaProps {
  value: string | number,
  onChange: any,
  className: string,
  label: string,
  placeholder: string,
  rows?: number
}

const TextArea: React.FC<TextAreaProps> = ({ value, onChange, className, label, placeholder, rows=3 }) => {
  return(
    <div className={className}>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-1 flex items-center">
        <textarea rows={rows} placeholder={placeholder} value={value} onChange={onChange} className='w-full border-2 border-gray-300 px-2 py-1 rounded-md focus:outline-none focus:border-indigo-500'></textarea>
      </div>
    </div>
  );
};

export default TextArea;