import React from "react";

interface InputProps {
  value: string | number,
  onChange: any,
  className: string,
  label: string,
  placeholder?: string,
  type: "number"|"text"|"email"|"datetime-local",
  prefix?: string,
  prefixPosition?: "start"|"end"
}

const Input: React.FC<InputProps> = ({ value, onChange, className, label, placeholder, type, prefix, prefixPosition }) => {
  return(
    <div className={className}>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-1 flex items-center">
        {prefixPosition==="start" ? (
          <span className="border-l-2 border-t-2 border-b-2 border-gray-300 bg-gray-100 px-2 py-1 rounded-l-md">
            {prefix}
          </span>
        ):null}
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className={`w-full ${prefix&&prefixPosition=="start" ? "rounded-r-md": (prefix&&prefixPosition=="end" ? "rounded-l-md" : "rounded-md")} border-2 border-gray-300 px-2 py-1 focus:outline-none focus:border-indigo-500`}
        />
        {prefixPosition==="end" ? (
          <span className="border-r-2 border-t-2 border-b-2 border-gray-300 bg-gray-100 px-2 py-1 rounded-r-md">
            {prefix}
          </span>
        ):null}
      </div>
    </div>
  );
};

export default Input;