import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { responseCard } from "../../../helpers/interfaces";
import { useMoralis, useWeb3Contract } from "react-moralis";
import { Loading, useNotification } from "@web3uikit/core";
import { getEllipsisTxt } from "@web3uikit/web3";
import { purpleButtonClass, whiteButtonClass } from "../../../helpers/styles";
import PendingModal from "../../../components/Modals/PendingModal";
import SuccessModal from "../../../components/Modals/SuccessModal";
import ErrorModal from "../../../components/Modals/ErrorModal";
import { getNotification, getPolygonLogo } from "../../../helpers/formatters";
import { getCardsAbi, getCardsAddress } from "../../../helpers/contract";
import { useLaunch, Window } from "../../XMTP";
import ChatBox from "./ChatBox";

interface ClaimModalProps {
  isVisible: boolean,
  close: any,
  card: responseCard,
  setClaimedFunction: any,
}

const ClaimModal: React.FC<ClaimModalProps> = ({ isVisible, close, card, setClaimedFunction }) => {

  const [isPendingModalVisible, setIsPendingModalVisible] = useState<boolean>(false);
  const openPendingModal = () => setIsPendingModalVisible(true);
  const closePendingModal = () => setIsPendingModalVisible(false);
  const [pendingText, setPendingText] = useState<string>("Please wait!");
  const [pendingComponent, setPendingComponent] = useState<any>(null);

  const [isErrorModalVisible, setIsErrorModalVisible] = useState<boolean>(false);
  const openErrorModal = () => setIsErrorModalVisible(true);
  const closeErrorModal = () => setIsErrorModalVisible(false);
  const [errorText, setErrorText] = useState<string>("Error ocurred, please try again or contact support!");

  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState<boolean>(false);
  const openSuccessModal = () => setIsSuccessModalVisible(true);
  const closeSuccessModal = () => setIsSuccessModalVisible(false);
  const [successText, setSuccessText] = useState<string>("You have successfully claimed:");

  const [lockedFunds, setLockedFunds] = useState<number|null>(null);
  const [isClaimed, setIsClaimed] = useState<boolean>(false);
  const [signer, setSigner] = useState<any>();

  const notify = useNotification();
  const { Moralis, user, setUserData } = useMoralis();
  const Contract = useWeb3Contract({
    contractAddress: getCardsAddress(),
    functionName: "withdrawAllFromCard",
    abi: getCardsAbi(),
    params: {
      id: Number(card.token_id),
    }
  });

  const claimAll = async() => {
    try{
      // fireworks animation
      // modals start
      // make tx
      // cloud call
      // modals finish
    
      // check if at least 1 amount is >0 and chAIN
      if(!user) {
        notify(getNotification("error", "Error! 😢", "Please connect your wallet!"));
        throw new Error("Please connect your wallet!");
      }
      if(lockedFunds ? lockedFunds<=0 : false) {
        notify(getNotification("error", "Error! 😢", "There is nothing to claim!"));
        throw new Error("There is nothing to claim! If you think this is wrong try again or contact our support team.");
      }
      
      // send tx to blockchain
      setPendingText("Please confirm transaction in your wallet . . .");
      openPendingModal();
      close();
      const tx: any = await Contract.runContractFunction();
      //notify(getNotification("info", "Pending! ⏳", "Transaction is pending, please wait."))
      setPendingText("Transaction is pending . . . Please wait!");
      setPendingComponent(
        <div>
          <p>View Transaction Details: <a target="_blank" href={`https://mumbai.polygonscan.com/tx/${tx.hash}`} rel="noreferrer">here</a>.</p>
        </div>
      );


      // after confirmed save to db that this has been claimed
      if(tx) {
        const receipt = await tx.wait();
        setPendingText("Transaction confirmed! Finishing up . . .");
        await setClaimed();
        await setClaimedFunction();
        setIsClaimed(true);
        closePendingModal();
        openSuccessModal();
      }
    }catch(err: any){
      console.log(err);
      closePendingModal();
      closeSuccessModal();
      setErrorText(err.message);
      openErrorModal();
    }
  };

  const setClaimed = async() => {
    try{
      let alreadyClaimed = user?.get("claimedCards");
      if(alreadyClaimed == undefined) {
        alreadyClaimed = [];
      }
      alreadyClaimed.push(Number(card.token_id));
      console.log(alreadyClaimed);
      await setUserData({
        claimedCards: alreadyClaimed
      });
    } catch(err: any) {
      console.log(err);
      throw new Error(err);
    }
  };
  
  const getSuccessModalComponent = () => {
    return(
      <div className='my-4 flex flex-row justify-center'>
        <p className="font-normal text-xl text-gray-900">{lockedFunds}</p>
        <img
          src={getPolygonLogo()}
          alt="Token Logo"
          className="rounded-full h-7 w-7 ml-4 mr-2"
        />
        <p className="font-bold text-xl text-gray-900">MATIC</p>
      </div>
    );
  };

  const displaySender = () => {
    return(
      <div className='flex flex-row'>
        <img
          src={card.metadata.attributes[3].value}
          alt="Sender Profile Picture"
          className='w-8 h-8 mr-3 bg-cover rounded-full'
        />
        <p className='font-medium text-xl text-gray-800'>
          {card.metadata.attributes[2].value}
          <span className='ml-2 text-gray-500'>(<a href={`https://mumbai.polygonscan.com/address/${card.metadata.attributes[4].value}`} target="_blank" rel="noreferrer">{getEllipsisTxt(card.metadata.attributes[4].value, 6)}</a>)</span>
        </p>
      </div>
    );
  };

  const getLockedFunds = async() => {
    try{
      const lockedFunds = await Moralis.Cloud.run("getLockedFunds", { id: card.token_id, chain: "0xa869" });
      setLockedFunds(lockedFunds);
    }catch(err){
      console.log(err);
    }
  };

  useEffect(() => {
    if(Contract.error) {
      console.log(Contract.error);
      closePendingModal();
      closeSuccessModal();
      openErrorModal();
      setErrorText(Contract.error.message);
    }
  }, [Contract.error]);

  useEffect(() => {
    if(Contract.data) {
      console.log(Contract.data);
    }
  }, [Contract.data]);


  useEffect(() => {
    if(isVisible) {
      getLockedFunds();
    }
  }, [isVisible]);

  const getSigner = async() => {
    try{
      const provider = await Moralis.enableWeb3();
      const _signer = provider.getSigner();
      setSigner(_signer);
    }catch(err: any){
      console.log(err);
    }
  };

  useEffect(() => {
    console.log(signer);
  }, [signer]);

  return(
    <>
      <Transition appear show={isVisible} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={close}>{/*remove this if needed onClose={close}*/}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-5 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-9xl transform overflow-hidden rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all">
                  <div className='flex flex-row justify-between mb-12'>
                    <Dialog.Title
                      as="h1"
                      className="text-5xl font-extrabold leading-6 text-gray-900 mt-2 flex flex-row"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 -m-5 pl-4 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7" />
                      </svg>
                      Claim Gift Card
                    </Dialog.Title>
                    <div onClick={close} className='cursor-pointer rounded-md hover:bg-gray-100 transition-all duration-300'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-9 w-9" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                    </div>
                  </div>
                  {card.metadata ? (<div>
                    {/**CONTENT */}
                    <div className='flex flex-row w-max'>
                      <div className='w-2/5 h-2/5 sticky top-0'>
                        <img
                          src={card.metadata.image}
                          alt="Card Cover Image"
                          className='h-full w-full rounded-2xl '
                        />
                      </div>
                      <div className='pl-8 pt-1 max-h-6/10 w-2/30'>
                        <p className='font-black text-5xl text-gray-900 mb-3'>{card.metadata.attributes[0].value}</p>
                        <p className='font-normal text-xl text-gray-600 italic mb-4'>{card.metadata.attributes[1].value}</p>
                        {displaySender()}
                        {signer ? (
                          <ChatBox signer={signer} address={card.metadata.attributes[4].value} />
                        ):null}
                        <button className={`${whiteButtonClass()} mt-6 font-normal`} onClick={() => getSigner()}>Message <span className="font-bold">{card.metadata.attributes[2].value}</span></button>
                      </div>
                    </div>
                    <div className="cursor-pointer flex flex-row mt-6 hover:underline">
                      <a href={`https://testnets.opensea.io/assets/mumbai/${getCardsAddress()}/${card.token_id}`} target="_blank" rel="noreferrer">
                        <img
                          src="https://seeklogo.com/images/O/opensea-logo-7DE9D85D62-seeklogo.com.png"
                          className="h-7 w-7"
                          alt="OpenSea Logo"
                        />
                      </a>
                      <a href={`https://testnets.opensea.io/assets/mumbai/${getCardsAddress()}/${card.token_id}`} target="_blank" rel="noreferrer"><p className="text-base ml-2">See In OpenSea</p></a>
                    </div>
                    <div className='w-full border-3 bg-gray-50 border-gray-300 hover:border-indigo-500 p-3 rounded-2xl transition-all duration-300 mt-8 mb-10'>
                      {!lockedFunds ? (
                        <div className='flex items-center justify-center align-middle'>
                          <Loading
                            fontSize={15}
                            size={24}
                            spinnerColor="#5a67d8"
                            spinnerType="wave"
                            text="Loading..."
                          />
                        </div>
                      ):(
                        <div className='px-4 pt-1 pb-3'>
                          <p className='font-extrabold text-3xl text-gray-800'>Value in Gift Card:</p>
                          <p className='font-light text-xl text-gray-600 mb-7'>Value that has been gifted to you in this gift card by <span className='font-normal'>{card.metadata.attributes[2].value}</span>.</p>
                          <div className='flex flex-row items-center justify-center align-middle'>
                            <p className="font-normal text-3xl text-gray-900">{lockedFunds}</p>
                            <img
                              src={getPolygonLogo()}
                              alt="Token Logo"
                              className="rounded-full h-7 w-7 ml-4 mr-2"
                            />
                            <p className="font-bold text-3xl text-gray-900">MATIC</p>
                          </div>
                        </div>
                      )}
                    </div>

                    <div>
                      <button
                        className={`${purpleButtonClass()} w-full text-center text-2xl rounded-xl`}
                        onClick={claimAll}
                      >
                        Claim
                      </button>
                    </div>
                  </div>):(
                    <p>Moralis api is still getting the metadata url of the NFT, please refresh!</p>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <PendingModal
        title='Please wait!'
        message={pendingText}
        isVisible={isPendingModalVisible}
        component={pendingComponent}
      />

      <SuccessModal
        title='😎 Claimed!'
        message={successText}
        isVisible={isSuccessModalVisible}
        close={closeSuccessModal}
        component={getSuccessModalComponent()}
      />

      <ErrorModal
        title="Something Went Wrong!"
        message={errorText}
        isVisible={isErrorModalVisible}
        close={closeErrorModal}
      />

    </>
  );
};

export default ClaimModal;