import React, { useEffect } from "react";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Tabs from "./components/Tabs/Tabs";
import RenderTabsContent from "./components/Tabs/RenderTabs";
import Top from "./components/Top/Top";
import {
  HashRouter,
  Routes,
  Route,
} from "react-router-dom";
import { checkWeb3 } from "./helpers/web3";
import Home from "./components/HomePage/HomePage";
import Footer from "./components/HomePage/partials/Footer";

const App = () => {

  useEffect(() => {
    (async function web3() {
      try{
        await checkWeb3();
      }catch(err: any){ 
        console.log(err);
      }
    })();
  }, []);

  return (
    <>
      <HashRouter>
        <Routes>
          <Route path="*" element={<Home />} />
          <Route
            path=""
            element={<Home />}
          />
          <Route path="/app/" element={
            <>
              <Navbar />
              <div className="mb-36">
                <Top />
                <div className="mt-4"></div>
                <Tabs tab="create" />
                <RenderTabsContent tab="create" />
              </div>
              <Footer />
            </>
          }
          />
          <Route path="/app/create" element={
            <>
              <Navbar />
              <div className="mb-36">
                <Top />
                <div className="mt-4"></div>
                <Tabs tab="create" />
                <RenderTabsContent tab="create" />
              </div>
              <Footer />
            </>
          }
          />
          <Route path="/app/drafts" element={
            <>
              <Navbar />
              <div className="mb-36">
                <Top />
                <div className="mt-4"></div>
                <Tabs tab="drafts" />
                <RenderTabsContent tab="drafts" />
              </div>
              <Footer />
            </>
          }
          />
          <Route path="/app/received" element={
            <>
              <Navbar />
              <div className="mb-36">
                <Top />
                <div className="mt-4"></div>
                <Tabs tab="received" />
                <RenderTabsContent tab="received" />
              </div>
              <Footer />
            </>
          }
          />
        </Routes>
      </HashRouter >
    </>
  );
};

export default App;
