import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Template, TemplateObj } from "../../helpers/interfaces";
import { getEllipsisTxt } from "@web3uikit/web3";
import { purpleButtonClass } from "../../helpers/styles";
import { getNotification, getPolygonLogo } from "../../helpers/formatters";
import { useMoralis, useWeb3Contract } from "react-moralis";
import { getStoreAbi, getStoreAddress } from "../../helpers/contract";
import ConfirmModal from "../Modals/ConfirmModal";
import PendingModal from "../Modals/PendingModal";
import SuccessModal from "../Modals/SuccessModal";
import ErrorModal from "../Modals/ErrorModal";
import { Loading, useNotification } from "@web3uikit/core";
import { ImArrowDown, ImArrowUp } from "react-icons/im";
import { TbArrowBigTop, TbArrowBigDown } from "react-icons/tb";

interface DetailsModalProps {
  isVisible: boolean,
  close: () => void,
  template: TemplateObj,
  select: (image: string) => void,
}

const DetailsModal: React.FC<DetailsModalProps> = ({ isVisible, close, template, select }) => {

  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false);
  const openConfirmModal = () => {
    if(image.length==0 || title.length==0 || description.length==0 || Number(price)<0) {
      notify(getNotification("error", "Error! 😢", "You must fill in all the fields!"));
      return;
    }
    setIsConfirmModalVisible(true);
  };
  const closeConfirmModal = () => setIsConfirmModalVisible(false);

  const [isErrorModalVisible, setIsErrorModalVisible] = useState<boolean>(false);
  const openErrorModal = () => setIsErrorModalVisible(true);
  const closeErrorModal = () => setIsErrorModalVisible(false);
  const [errorMessage, setErrorMessage] = useState<string>("An Error ocurred, please try again or contact out support team.");

  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState<boolean>(false);
  const openSuccessModal = () => setIsSuccessModalVisible(true);
  const closeSuccessModal = () => setIsSuccessModalVisible(false);

  const [isPendingModalVisible, setIsPendingModalVisible] = useState<boolean>(false);
  const openPendingModal = () => setIsPendingModalVisible(true);
  const closePendingModal = () => setIsPendingModalVisible(false);
  const [pendingTitle, setPendingTitle] = useState<string>("Please Wait!");
  const [pendingMessage, setPendingMessage] = useState<string>("");
  const [pendingComponent, setPendingComponent] = useState<any>(null);

  const [hasVoted, setHasVoted] = useState<boolean|null>(null);

  const { image, title, description, price, creator } = template.metadataObj;
  const notify = useNotification();
  const { Moralis, user } = useMoralis();

  const Purchase = useWeb3Contract({
    contractAddress: getStoreAddress(),
    functionName: "buyTemplate",
    msgValue: template.price,
    abi: getStoreAbi(),
    params: {
      _id: Number(template.id),
    }
  });

  const Vote = useWeb3Contract({
    contractAddress: getStoreAddress(),
    functionName: "upvoteTemplate",
    abi: getStoreAbi(),
    params: {
      _id: Number(template.id)
    }
  });

  const displayPrice = () => {
    if(price==0) return "FREE";
    else return `${price} MATIC`;
  };

  const handlePurchase = () => {
    if(price==0) select(image);
    else openConfirmModal();
  };

  const purchase = async() => {
    try{
      if(!template) {
        notify(getNotification("error", "Error! 😢", "Something went wrong!"));
        return;
      }
      closeConfirmModal();
      openPendingModal();

      setPendingMessage("Please confirm transaction in your wallet . . .");
      const tx: any = await Purchase.runContractFunction();
      console.log(tx);
      setPendingMessage("Transaction pending . . . Please wait!");
      setPendingComponent(
        <div>
          <p className="text-lg text-gray-600">View Transaction Details: <a target="_blank" href={`https://mumbai.polygonscan.com/tx/${tx.hash}`} rel="noreferrer">here</a>.</p>
        </div>
      );
      
      if(tx) {
        const receipt = await tx.wait();
        console.log(receipt);
      }

      notify(getNotification("success", "Success! 😀", "You have bought the template successfully!"));
      closePendingModal();
      openSuccessModal();
      closeErrorModal();
      select(image);
      close();
    }catch(err: any){
      notify(getNotification("error", "Error! 😢", "Something went wrong while purchasing your template!"));
      console.log(err);
      closePendingModal();
      closeSuccessModal();
      openErrorModal();
      setErrorMessage(err.message);
    }
  };

  const vote = async(side: "up"|"down") => {
    try{
      if(hasVoted) {
        notify(getNotification("info", "New Notification!", "You have already voted! You can't vote again!"));
        return;
      }
      notify(getNotification("info", "Confirm TX!", "Please confirm transaction in your wallet!"));
      const options = {
        contractAddress: getStoreAddress(),
        functionName: `${side}voteTemplate`,
        abi: getStoreAbi(),
        params: {
          _id: Number(template.id)
        }
      };
      const tx: any = await Vote.runContractFunction({ params: options });
      console.log(tx); 
      if(tx) {
        notify(getNotification("info", "TX pending . . . ⌛", "Transaction is pending...Please wait!"));
        const receipt = await tx.wait();
        console.log(receipt);
        await getHasVoted();
        if(side=="up") template.upvotes = (Number(template.upvotes) + 1).toString();
        if(side=="down") template.downvotes = (Number(template.downvotes) + 1).toString();
        notify(getNotification("success", "Success! 😀", `You have successfully ${side}voted this template! Thank you for your vote!`));
      }
    }catch(err: any){
      notify(getNotification("error", "Error! 😢", "Something went wrong while ${side}voting this template!"));
    }
  };

  useEffect(() => {
    if(Purchase.error) {
      console.log(Purchase.error);
      closePendingModal();
      closeSuccessModal();
      openErrorModal();
      setErrorMessage(Purchase.error.message);
    }
  }, [Purchase.error]);

  useEffect(() => {
    if(Purchase.data) {
      console.log(Purchase.data);
    }
  }, [Purchase.data]);

  useEffect(() => {
    if(Vote.error) {
      console.log(Vote.error);
      notify(getNotification("error", "Error! 😢", Vote.error.message));
    }
  }, [Vote.error]);

  useEffect(() => {
    if(Vote.data) {
      console.log(Vote.data);
    }
  }, [Vote.data]);

  useEffect(() => {
    if(isVisible) {
      (async function _getHasVoted() {
        await getHasVoted();
      })();
    }
  }, [isVisible]);

  const getHasVoted = async() => {
    if(user) {
      try{
        const res: boolean = await Moralis.Cloud.run("getHasVoted", { address: user.get("ethAddress"), id: template.id });
        console.log(res);
        setHasVoted(res);
      }catch(err: any){
        notify(getNotification("error", "Error! 😢", "Something went wrong while purchasing your template!"));
      }
    }
  };

  return(
    <>
      <Transition appear show={isVisible} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all max-w-10xl">
                  <div className='flex flex-row justify-between mb-10 mt-1 ml-1'>
                    <Dialog.Title
                      as="h1"
                      className="text-5xl font-extrabold leading-6 text-gray-900 mt-2 flex flex-row"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-16 w-16 -m-5 pl-4 mr-3">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                      </svg>
                      Template #{template.id} Details
                    </Dialog.Title>
                    <div onClick={close} className='cursor-pointer rounded-md hover:bg-gray-100 transition-all duration-300'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-9 w-9" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                    </div>
                  </div>
                  <div className="bg-white">
                    {/** MODAL CONTENT */}
                    <div className='flex flex-row w-max'>
                      <div className='w-2/5 h-2/5 sticky top-0 watermarked'>
                        <img
                          src={image}
                          alt="Card Cover Image"
                          className='h-full w-full rounded-2xl max-w-4/5'
                        />
                      </div>
                      <div className='pl-8 pt-1 max-h-6/10 w-2/30'>
                        <p className='font-black text-5xl text-gray-900 mb-3'>{title}</p>
                        <p className='font-normal text-xl text-gray-600 italic mb-4'>{description}</p>
                        <p className="block text-sm font-medium text-gray-700">Creator:</p>
                        <div className='flex flex-row'>
                          <img
                            src={creator.pfp}
                            alt="Sender Profile Picture"
                            className='w-8 h-8 mr-3'
                          />
                          <p className='font-medium text-xl text-gray-800'>
                            {creator.name}
                            <span className='ml-2 text-gray-500'>(<a href={`https://mumbai.polygonscan.com/address/${creator.address}`} target="_blank" rel="noreferrer">{getEllipsisTxt(creator.address, 6)}</a>)</span>
                          </p>
                        </div>
                        <div className='w-full border-3 bg-gray-50 border-gray-300 hover:border-indigo-500 p-3 rounded-2xl transition-all duration-300 mt-6 mb-6'>
                          <div className='px-4 pt-1 pb-3'>
                            <p className='font-extrabold text-3xl text-gray-800'>Price:</p>
                            <p className='font-light text-xl text-gray-600 mb-7'>After you pay this price you will be able to use this gift card cover - created by: <span className='font-normal'>{creator.name}</span>.</p>
                            <div className='flex flex-row items-center justify-center align-middle'>
                              <p className="font-normal text-3xl text-gray-900">{price}</p>
                              <img
                                src={getPolygonLogo()}
                                alt="Token Logo"
                                className="rounded-full h-7 w-7 ml-4 mr-2"
                              />
                              <p className="font-bold text-3xl text-gray-900">MATIC</p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <p className="block text-sm font-medium text-gray-700">Vote:</p>
                          {hasVoted==null ? (
                            <Loading
                              fontSize={15}
                              size={24}
                              spinnerColor="#5a67d8"
                              spinnerType="wave"
                              text="Loading..."
                            />
                          ):(
                            <div className="flex">
                              <div className="mr-8 cursor-pointer" onClick={() => vote("up")}>
                                <TbArrowBigTop className="text-5xl text-gray-600 hover:text-gray-900" />
                                <p className="ml-4 text-xl font-bold">{template.upvotes}</p>
                              </div>
                              <div className="cursor-pointer" onClick={() => vote("up")}>
                                <TbArrowBigDown className="text-5xl text-gray-600 hover:text-gray-900" />
                                <p className="ml-4 text-xl font-bold">{template.downvotes}</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <button
                      className={`${purpleButtonClass()} w-full rounded-lg text-xl mt-7`}
                      onClick={handlePurchase}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                      </svg>
                      {price==0 ? "Select":"Purchase"} - {displayPrice()}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <ConfirmModal
        title="Confirm Purchase"
        message={`You will be charged ${price} MATIC to be able to use this template! There is not refunding and this action is irreversible!`}
        isVisible={isConfirmModalVisible}
        close={closeConfirmModal}
        confirmFunction={purchase}
      />

      <PendingModal
        isVisible={isPendingModalVisible}
        title={pendingTitle}
        message={pendingMessage}
        component={pendingComponent}
      />

      <SuccessModal
        isVisible={isSuccessModalVisible}
        close={closeSuccessModal}
        title="Success!"
        message='You have successfully purchased a template!'
      />

      <ErrorModal
        isVisible={isErrorModalVisible}
        close={closeErrorModal}
        title={"Something went wrong!"}
        message={errorMessage}
      />
    </>
  );
};

export default DetailsModal;