import Moralis from "moralis-v1";
import { getMumbaiChainId } from "./formatters";


export const checkWeb3 = async() => {
  await Moralis.start({ serverUrl: "https://fs3ns8hlsyud.usemoralis.com:2053/server", appId: "Kdje2CuRj0tPh3NaPI9CWIn3Z57vGEFUKcPl6Of1" });
  const user = Moralis.User.current();
  console.log("in web3");
  if(!Moralis.isWeb3Enabled() && user) {
    await Moralis.enableWeb3({ chainId: getMumbaiChainId() });
  }else{
    console.log("else");
  }
};