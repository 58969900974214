import { Web3Storage } from "web3.storage/dist/bundle.esm.min.js";

function getAccessToken () {
  // If you're just testing, you can paste in a token
  // and uncomment the following line:
  // return 'paste-your-token-here'

  // In a real app, it's better to read an access token from an
  // environement variable or other configuration that's kept outside of
  // your code base. For this to work, you need to set the
  // WEB3STORAGE_TOKEN environment variable before you run your code.
  return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDVmNzMyRWY5NmEzOThEYjE2N2I2OUI3QTk1ODQ0REM5NWI0OEQ5OEYiLCJpc3MiOiJ3ZWIzLXN0b3JhZ2UiLCJpYXQiOjE2NjI0MDE2MTMyMzksIm5hbWUiOiJIYWNrYXRob24ifQ.mLeNIlVrW5Yb-avWu3nMwng7JF1wtGI5q8KWsNiMxSY";
}

export const createStorageClient = () => {
  return new Web3Storage({ token: getAccessToken() });
};



/**
 * Display a URL in the output area as a clickable link.
 * @param {string} url 
 */
export function showLink(url) {
  const output = document.getElementById("output");
  if (!output) {
    return;
  }
  const node = document.createElement("a");
  node.href = url;
  node.target = "_external";
  node.innerText = `> 🔗 ${url}`;
  output.appendChild(node);
}

// #endregion upload-view

/**
 * Makes a div containing an icon, followed by a text label
 * @param {string} iconClass class for the icon, e.g. 'fontawesome-share'
 * @param {string} labelText
 * @returns {HTMLDivElement}
 */
export function iconLabel(iconClass, labelText) {
  const label = document.createElement("span");
  label.textContent = labelText;
  const icon = document.createElement("span");
  icon.className = iconClass;
  const div = document.createElement("div");
  div.appendChild(icon);
  div.appendChild(label);
  return div;
}

/**
 * Sets window location to the given path, if we're not already there.
 * @param {string} path 
 */
export function navToPath(path) {
  if (window.location.pathname !== path) {
    window.location.pathname = path;
  }
}

/**
 * Set window location to the settings page.
 */
export function navToSettings() {
  navToPath("/settings.html");
}

/**
 * Return an IPFS gateway URL for the given CID and path
 * @param {string} cid 
 * @param {string} path 
 * @returns {string}
 */
export function makeGatewayURL(cid, path) {
  return `https://${cid}.ipfs.dweb.link/${encodeURIComponent(path)}`;
}

/**
 * Make a File object with the given filename, containing the given object (serialized to JSON).
 * @param {string} filename filename for the returned File object
 * @param {object} obj a JSON-serializable object
 * @returns {File}
 */
export function jsonFile(filename, obj) {
  return new File([JSON.stringify(obj)], filename);
}

/**
 * Removes any saved token from local storage
 */
export function deleteSavedToken() {
  localStorage.removeItem("w3storage-token");
}

/**
 * Hides the given DOM element by applying a "hidden" class,
 * which sets 'display: none'
 * @param {HTMLElement} el 
 */
export function hideElement(el) {
  el.classList.add("hidden");
}

/**
 * Removes the 'hidden' class from the given DOM element.
 * @param {HTMLElement} el 
 */
export function showElement(el) {
  el.classList.remove("hidden");
}

/**
 * Shows a message to the user in a small popup box that fades away after a few seconds.
 * @param {string} message message to display
 */
export function showPopupMessage(message) {
  const snackbar = document.getElementById("snackbar");
  if (!snackbar) {
    return;
  }
  snackbar.textContent = message;
  snackbar.classList.add("show");
  setTimeout(() => snackbar.classList.remove("show"), 3000);
}