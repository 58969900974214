import React from "react";
import { Blockie } from "@web3uikit/web3";
import { useMoralis } from "react-moralis";

interface PFPProps {
  stylesBlockie?: string,
  stylesImg?: string,
  scale?: number,
  displayBlockie?: boolean
}

const PFP: React.FC<PFPProps> = ({ stylesBlockie, stylesImg, scale, displayBlockie }) => {

  const { user } = useMoralis();

  if(user?.get("pfp") || !displayBlockie) {
    if((user?.get("pfp") != null && user?.get("pfp") != undefined) || displayBlockie) {
      return(
        <img
          alt="Profile Picture"
          src={user?.get("pfp")}
          className={`${stylesImg} object-cover`}
        />
      );
    }else{
      return(
        <Blockie scale={scale} seed={user?.get("ethAddress")} className={stylesBlockie} />
      );
    }
  }else{
    return(
      <Blockie scale={scale} seed={user?.get("ethAddress")} className={stylesBlockie} />
    );
  }
};

export default PFP;