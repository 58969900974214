import React, { useState } from "react";
import { useMoralis } from "react-moralis";
import { getCoverImage } from "../../../helpers/formatters";
import { responseCard } from "../../../helpers/interfaces";
import { purpleButtonClass } from "../../../helpers/styles";
import ClaimModal from "./ClaimModal";

interface ReceivedCardProps {
  card: responseCard,
  setClaimedFunction: any,
}

const ReceivedCard: React.FC<ReceivedCardProps> = ({ card, setClaimedFunction }) => {

  const [isClaimModalVisible, setIsClaimModalVisible] = useState<boolean>(false);
  const openClaimModal = () => setIsClaimModalVisible(true);
  const closeClaimModal = () => setIsClaimModalVisible(false);

  const getImageUrl = () => card.claimed ? (card.metadata ? card.metadata.image:"https://cdn4.iconfinder.com/data/icons/ui-beast-4/32/Ui-12-512.png") : getCoverImage();

  return(
    <>
      <div className="border-3 bg-gray-50 border-gray-300 hover:border-indigo-500 p-4 rounded-3xl h-fit transition-all duration-200">
        <img
          src={getImageUrl()}
          alt="Gift Card Image"
          className="rounded-2xl w-full h-full"
        />
        <button
          onClick={openClaimModal}
          className={`${purpleButtonClass} rounded-xl w-full mt-5 text-xl`}
        >
          {card.claimed ? "See":"Open"}
        </button>
      </div>

      <ClaimModal
        isVisible={isClaimModalVisible}
        close={closeClaimModal}
        card={card}
        setClaimedFunction={setClaimedFunction}
      />

    </>
  );
};

export default ReceivedCard;