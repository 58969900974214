import React from "react";
import { cardObj } from "../../helpers/interfaces";
import { Blockie } from "@web3uikit/web3";
import { getPolygonLogo } from "../../helpers/formatters";


interface DisplayCardProps {
  card: cardObj
}


const DisplayCard: React.FC<DisplayCardProps> = ({ card }) => {
  return(
    <>
      <div className="flex flex-row max-w-2/4">
        <img
          src={card.image}
          alt="Card Image"
          className="h-full rounded-2xl mr-5"
        />
        <div>
          <p className="font-extrabold text-3xl mb-3 text-gray-900">{card.title}</p>
          <div className="overflow-scroll overflow-x-hidden purple-scrollbar w-full mb-2 max-h-6/10">
            <p className="font-normal text-xl text-gray-700">{card.message}</p>
          </div>
          <div className="flex flex-row mt-3">
            {card.sender.pfp ? (
              <img
                src={card.sender.pfp}
                alt="Sender Profile Image"
                className="h-7 w-7 rounded-full"
              />
            ):(
              <Blockie
                seed={card.sender.name}
              />
            )}
            <p className="font-bold text-lg text-gray-900 ml-2">{card.sender.name}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between">
        <div className="overflow-scroll overflow-x-hidden purple-scrollbar w-full mb-2 max-h-9/10">
          <div className="flex flex-row"> 
            <p className="font-bold text-2xl text-gray-900">{card.value}</p>
            <img
              src={getPolygonLogo()}
              alt="Polygon Logo"
              className="rounded-full h-10 w-10 ml-4 mr-2"
            />
            <p className="font-extrabold text-2xl text-gray-900">MATIC</p>
          </div>
        </div>
        <p className="font-bold text-lg text-gray-900">Quantity: {card.quantity}</p>
      </div>
    </>
  );
};

export default DisplayCard;