import React, { useRef, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";


interface SuccessModalProps {
  title: string,
  message: string,
  isVisible: boolean,
  close: any,
  secondButton?: any,
  component?: any
}


const SuccessModal: React.FC<SuccessModalProps> = ({ title, message, secondButton=null, isVisible, close, component }) => {
  
  const cancelButtonRef = useRef(null);

  return(
    <Transition.Root show={isVisible} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-4">
                  <div className="flex items-center flex-col">
                    <div className="mx-auto flex-shrink-0 mb-6 flex items-center justify-center h-20 w-20 rounded-full bg-green-100 sm:mx-0">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-14 w-14 text-green-500">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>
                    </div>
                    <div className="mt-6 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-2xl font-bold text-center text-gray-900">
                        {title}
                      </Dialog.Title>
                      <div className="mt-3 p-3 bg-gray-50 rounded-xl">
                        <p className="text-lg text-gray-600 text-center">
                          {message}
                        </p>
                        {component}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 py-3 mt-4 px-6 flex flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 w-auto sm:text-sm"
                    onClick={close}
                  >
                    😀
                    Nice!
                  </button>
                  {secondButton}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>  
  );
};

export default SuccessModal;