import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MoralisProvider } from "react-moralis";
import { NotificationProvider } from "@web3uikit/core";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MoralisProvider
      appId="Kdje2CuRj0tPh3NaPI9CWIn3Z57vGEFUKcPl6Of1"
      serverUrl="https://fs3ns8hlsyud.usemoralis.com:2053/server"
    >
      <NotificationProvider>
        <App />
      </NotificationProvider>
    </MoralisProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/**
 *     <MoralisProvider
      appId="Kdje2CuRj0tPh3NaPI9CWIn3Z57vGEFUKcPl6Of1"
      serverUrl="https://fs3ns8hlsyud.usemoralis.com:2053/server"
    >
 */
reportWebVitals();
