import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ImageInput from "../Basic/ImageInput";
import Input from "../Basic/Input";
import TextArea from "../Basic/TextArea";
import { purpleButtonClass } from "../../helpers/styles";
import { useMoralis, useWeb3Contract } from "react-moralis";
import { getStoreAbi, getStoreAddress } from "../../helpers/contract";
import { useNotification } from "@web3uikit/core";
import { getNotification } from "../../helpers/formatters";
import ConfirmModal from "../Modals/ConfirmModal";
import PendingModal from "../Modals/PendingModal";
import SuccessModal from "../Modals/SuccessModal";
import ErrorModal from "../Modals/ErrorModal";
import { Template } from "../../helpers/interfaces";
import { createStorageClient, jsonFile, makeGatewayURL } from "../../helpers/storage";

interface UploadModalProps {
  isVisible: boolean,
  close: () => void,
}

const UploadModal: React.FC<UploadModalProps> = ({ isVisible, close }) => {

  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false);
  const openConfirmModal = () => {
    if(image.length==0 || title.length==0 || description.length==0 || Number(price)<0) {
      notify(getNotification("error", "Error! 😢", "You must fill in all the fields!"));
      return;
    }
    setIsConfirmModalVisible(true);
  };
  const closeConfirmModal = () => setIsConfirmModalVisible(false);

  const [isErrorModalVisible, setIsErrorModalVisible] = useState<boolean>(false);
  const openErrorModal = () => setIsErrorModalVisible(true);
  const closeErrorModal = () => setIsErrorModalVisible(false);
  const [errorMessage, setErrorMessage] = useState<string>("An Error ocurred, please try again or contact out support team.");

  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState<boolean>(false);
  const openSuccessModal = () => setIsSuccessModalVisible(true);
  const closeSuccessModal = () => setIsSuccessModalVisible(false);

  const [isPendingModalVisible, setIsPendingModalVisible] = useState<boolean>(false);
  const openPendingModal = () => setIsPendingModalVisible(true);
  const closePendingModal = () => setIsPendingModalVisible(false);
  const [pendingTitle, setPendingTitle] = useState<string>("Please Wait!");
  const [pendingMessage, setPendingMessage] = useState<string>("");
  const [pendingComponent, setPendingComponent] = useState<any>(null);

  const [image, setImage] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [price, setPrice] = useState<string>("");

  const { Moralis, user } = useMoralis();
  const notify = useNotification();
  const Contract = useWeb3Contract({
    contractAddress: getStoreAddress(),
    functionName: "publishTemplate",
    abi: getStoreAbi(),
    params: {
      _metadata: "",
      price: ""
    }
  });

  /**
   * sell yor template function
   * data needed:
   *  - name
   *  - description
   *  -? recommended message and title
   *  - price
   *  - tx on blockchain - data uploaded with web3.storage
   */
  /**
   * BUY TEMPLATE
   *  - if free
   *      - select image
   *  - else 
   *      - wait for event to be emitted and than select  
   */

  const publishTemplate = async() => {
    try{
      if(image.length==0 || title.length==0 || description.length==0 || Number(price)<0) {
        notify(getNotification("error", "Error! 😢", "You must fill in all the fields!"));
        return;
      }
      closeConfirmModal();
      openPendingModal();

      setPendingMessage("Uploading metadata! . . . Please wait!");
      const metadata = await uploadMetadata();
      const formattedPrice = Moralis.Units.ETH(price);
      console.log(metadata, formattedPrice);
      const options = {
        contractAddress: getStoreAddress(),
        functionName: "publishTemplate",
        abi: getStoreAbi(),
        params: {
          _metadata: metadata,
          _price: formattedPrice
        }
      };

      setPendingMessage("Please confirm transaction in your wallet . . .");
      const tx: any = await Contract.runContractFunction({ params: options });
      console.log(tx);

      setPendingMessage("Transaction pending . . . Please wait!");
      setPendingComponent(
        <div>
          <p className="text-lg text-gray-600">View Transaction Details: <a target="_blank" href={`https://mumbai.polygonscan.com/tx/${tx.hash}`} rel="noreferrer">here</a>.</p>
        </div>
      );
      let receipt: any;
      if(tx) {
        receipt = await tx.wait();
        console.log(receipt);
      }

      notify(getNotification("success", "Success! 😀", "Your template was uploaded to store successfully!"));
      closePendingModal();
      openSuccessModal();
      closeErrorModal();
      close();
    }catch(err: any){
      notify(getNotification("error", "Error! 😢", "Something went wrong while uploading your template to the store!"));
      console.log(err);
      closePendingModal();
      closeSuccessModal();
      openErrorModal();
      setErrorMessage(err.message);
    }
  };

  const uploadMetadata = async() => {
    try{
      if(!user) throw new Error("Connect your wallet!");

      notify(getNotification("info", "Uploading! ⏳", "Uploading metadata, please wait...!"));
      const namePrefix = "TemplateMetadata";
      const uploadName = [namePrefix, "TemplateMetadata"].join("|");

      const obj: Template = {
        title: title,
        description: description,
        price: Number(price),
        image: image,
        creator: {
          name: user.get("username"),
          pfp: user.get("pfp"),
          address: user.get("ethAddress")
        }
      };
      
      const metadataFile = jsonFile("metadata.json", obj);
      const web3storage = createStorageClient();
      const cid = await web3storage.put([metadataFile], {
        name: uploadName,
      });
    
      const metadataGatewayURL = makeGatewayURL(cid, "metadata.json");
      const metadataURI = `ipfs://${cid}/metadata.json`;
      notify(getNotification("success", "Success! 😀", "Your metadata was uploaded successfully!"));
      const toReturn = { cid, metadataGatewayURL, metadataURI };
      console.log(toReturn);
      return toReturn.metadataGatewayURL;
    }catch(err: any){
      throw new Error("Something went wrong while saving metadata!");
    }
  };

  useEffect(() => {
    if(Contract.error) {
      console.log(Contract.error);
      closePendingModal();
      closeSuccessModal();
      openErrorModal();
      setErrorMessage(Contract.error.message);
    }
  }, [Contract.error]);

  useEffect(() => {
    if(Contract.data) {
      console.log(Contract.data);
    }
  }, [Contract.data]);

  return(
    <>
      <Transition appear show={isVisible} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-9xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className='flex flex-row justify-between mb-6 mt-1 ml-1'>
                    <Dialog.Title
                      as="h1"
                      className="text-5xl font-extrabold leading-6 text-gray-900 mt-2 flex flex-row"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-16 w-16 -m-5 pl-4 mr-3">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 6h.008v.008H6V6z" />
                      </svg>
                      Sell Your Template
                    </Dialog.Title>
                    <div onClick={close} className='cursor-pointer rounded-md hover:bg-gray-100 transition-all duration-300'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-9 w-9" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                    </div>
                  </div>
                  <div className="bg-white">
                    {/** MODAL CONTENT */}
                    <p className="font-light text-xl">Create your own template for the template store.</p>
                    <p className="block text-sm font-medium text-gray-700 mt-2">Template Image:</p>
                    <ImageInput
                      image={image}
                      setImage={setImage}
                      showTemplates={false}
                    />
                    <Input
                      type="text"
                      placeholder="Template Title"
                      label="Template Title:"
                      value={title}
                      onChange={(e: any) => setTitle(e.target.value)}
                      className="mt-1"
                    />
                    <TextArea
                      placeholder="Short description for your template"
                      label="Description of your Template"
                      className="mt-2"
                      onChange={(e: any) => setDescription(e.target.value)}
                      value={description}
                    />
                    <Input
                      type="number"
                      placeholder="Price"
                      label="Price for your Template:"
                      value={price}
                      onChange={(e: any) => setPrice(e.target.value)}
                      className="mt-2"
                      prefix="MATIC"
                      prefixPosition="end"
                    />
                    <p className="text-gray-600 font-normal ml-1">Amount that others will pay you for using your template.</p>

                    <button
                      className={`${purpleButtonClass()} w-full rounded-lg text-xl mt-10`}
                      onClick={openConfirmModal}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6 mr-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 6h.008v.008H6V6z" />
                      </svg>
                      Upload it to the store
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <ConfirmModal
        title="Confirm Upload"
        message="Your Template will be uploaded to the templates store, visible by anyone. After this action you can't edit your template ever again!"
        isVisible={isConfirmModalVisible}
        close={closeConfirmModal}
        confirmFunction={publishTemplate}
      />

      <PendingModal
        isVisible={isPendingModalVisible}
        title={pendingTitle}
        message={pendingMessage}
        component={pendingComponent}
      />

      <SuccessModal
        isVisible={isSuccessModalVisible}
        close={closeSuccessModal}
        title="Success!"
        message='Your Template has been successfully uploaded to the templates store!'
      />

      <ErrorModal
        isVisible={isErrorModalVisible}
        close={closeErrorModal}
        title={"Something went wrong!"}
        message={errorMessage}
      />
    </>
  );
};

export default UploadModal;