import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useMoralis } from "react-moralis";
import { useNotification } from "@web3uikit/core";
import { getNotification } from "../../helpers/formatters";
import ErrorModal from "../Modals/ErrorModal";
import { Blockie, WalletModal } from "@web3uikit/web3";
import { createStorageClient, jsonFile, makeGatewayURL } from "../../helpers/storage";

interface SettingsModalProps {
  isVisible: boolean,
  close: () => void
}

const errorModalMessage = "Something went wrong while updating changes! Please try again or contact our support team.";
const errorModalTitle = "Error!";

const SettingsModal: React.FC<SettingsModalProps> = ({ isVisible, close }) => {
  
  const [isErrorModalVisible, setIsErrorModalVisible] = useState<boolean>(false);
  const openErrorModal = () => setIsErrorModalVisible(true);
  const closeErrorModal = () => setIsErrorModalVisible(false);

  const [isConnectWalletModalVisible, setIsConnectWalletModalVisible] = useState<boolean>(false);
  const openConnectWalletModal = () => setIsConnectWalletModalVisible(true);
  const closeConnectWalletModal = () => setIsConnectWalletModalVisible(false);

  const [username, setUsername] = useState<string>("");
  const [receivedCards, setReceivedCards] = useState<boolean>(false);
  const [announcements, setAnnouncements] = useState<boolean>(false);
  const [blog, setBlog] = useState<boolean>(false);
  const [news, setNews] = useState<boolean>(false);
  const [phoneNotifications, setPhoneNotifications] = useState<"all" | "no" | "same">("no");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [pfp, setPfp] = useState<string>("");
  const [banner, setBanner] = useState<string>("");

  const removePhoto = () => setPfp("");
  const removeBanner = () => setBanner("");

  const { user, setUserData, refetchUserData, Moralis } = useMoralis();
  const notify = useNotification();

  const handleImageInput = async(event: any, type: "pfp" | "banner") => {
    try{
      notify(getNotification("info", "Uploading! â³", "Uploading image, please wait...!"));
      const image = event.target.files[0];
      const file = new Moralis.File(image.name, image);
      await file.saveIPFS();
      const ipfsUrl = file.ipfs();
      console.log(type);
      if(type == "pfp") setPfp(ipfsUrl);
      if(type == "banner") setBanner(ipfsUrl);
      notify(getNotification("success", "Success! ðŸ˜€", "Your image was uploaded successfully!"));
    }catch(err: any){
      notify(getNotification("error", "Error! ðŸ˜¢", "Something went wrong while uploading your image!"));
    }
  };

  const renderPfp = () => {
    if(pfp === "") {
      return (
        <Blockie
          scale={6.5}
          seed={user?.get("ethAddress")}
          className="rounded-full border-4 border-gray-300 transition-all duration-300"
        />
      );
    }else{
      return(
        <img
          src={pfp}
          alt="Profile Picture"
          className='rounded-full border-4 border-gray-300 transition-all duration-300 w-16 h-16'
        />
      );
    }
  };

  const renderBanner = () => {
    if(banner === "") {
      return (
        <div className="space-y-1 text-center mt-6 mb-6">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-gray-600">
            <label
              htmlFor="banner-upload"
              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
            >
              <span>Upload a file</span>
              <input onChange={(e: any) => handleImageInput(e, "banner")} id="banner-upload" name="banner-upload" type="file" className="sr-only" />
            </label>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
        </div>
      );
    }else{
      return(
        <img
          src={banner}
          alt="Banner Photo"
          className='w-full rounded-lg ml-auto mr-auto max-h-72 object-cover'
        />
      );
    }
  };
  
  const saveGeneralSettings = async() => {
    try{
      const newData: any = {
        pfp: pfp.length>10 ? pfp : null,
        banner: banner.length>10 ? banner : null,
        username: username,
      };

      await setUserData(newData);
      notify(getNotification("success", "Success! 😀", "Changes uploaded successfully!"));
    }catch(err){
      openErrorModal();
    }
  };

  useEffect(() => {
    if(user) {
      const _username = user.get("username");
      setUsername(_username ? _username : "");
      const _banner = user.get("banner");
      setBanner(_banner ? _banner : "");
      const _pfp = user.get("pfp");
      setPfp(_pfp ? _pfp : "");

      const _notifications = user.get("notifications");
      const _email = user.get("email");
      setEmail(_email ? _email : "");
      const _phone = user.get("phone");
      setPhone(_phone ? _phone : "");
      const _phoneNotifications = user.get("phoneNotificationsType");
      console.log(_phoneNotifications ? _phoneNotifications : "no");
      setPhoneNotifications(_phoneNotifications);
      const _announcements = _notifications ? _notifications.announcements.email : false;
      setAnnouncements(_announcements);
      const _receivedCards = _notifications ? _notifications.receivedCards.email : false;
      setReceivedCards(_receivedCards);
      const _blog = _notifications ? _notifications.blog.email : false;
      setBlog(_blog);
      const _news = _notifications ? _notifications.news.email : false;
      setNews(_news);
    }
  }, [user]);

  return(
    <>
      <Transition appear show={isVisible} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-7xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className='flex flex-row justify-between mb-6 mt-1 ml-1'>
                    <Dialog.Title
                      as="h1"
                      className="text-5xl font-extrabold leading-6 text-gray-900"
                    >
                    Settings
                    </Dialog.Title>
                    <div onClick={close} className='cursor-pointer rounded-md hover:bg-gray-100 transition-all duration-300'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-9 w-9" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                    </div>
                  </div>
                  {user ? (
                    <div className='p-6 bg-indigo-50 rounded-2xl'>
                      {/**general profile settings */}
                      <div className="rounded-2xl sm:overflow-hidden">
                        <div className="p-4 bg-white">
                          <p className='font-extrabold text-2xl mb-1'>General Settings</p>
                          <p className="text-sm text-gray-500 mb-2">This information is visible to anyone, but can only be changed by you.</p>
                          <div className='mb-6'>
                            <label className="block text-sm font-medium text-gray-700">Username</label>
                            <div className="mt-1 flex items-center">
                              <input placeholder='Johny' value={username} onChange={(e: any) => setUsername(e.target.value)} className='w-full border-2 border-gray-300 px-2 py-1 rounded-md focus:outline-none focus:border-indigo-500' />
                            </div>
                          </div>
                          <div className='mb-6'>
                            <label className="block text-sm font-medium text-gray-700">Profile Photo</label>
                            <div className="mt-1 flex items-center">
                              {renderPfp()}
                              <button
                                type="button"
                                className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                <label
                                  htmlFor="file-upload"
                                  className='cursor-pointer'
                                >
                                  <span>Change</span>
                                  <input onChange={(e: any) => handleImageInput(e, "pfp")} id="file-upload" name="file-upload" type="file" className="sr-only" />
                                </label>
                              </button>
                              {pfp.length>10 ? (
                                <button
                                  onClick={removePhoto}
                                  type="button"
                                  className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                              Remove Photo
                                </button>
                              ):null}
                            </div>
                            <p className='text-sm font-extralight text-gray-500'>*recommended: small square image</p>
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">Banner Photo</label>
                            <div className="mt-1 flex justify-center px-2 pt-2 pb-2 border-2 border-gray-300 border-dashed rounded-lg">
                              {renderBanner()}
                            </div>
                            {banner!=="" ? (
                              <button
                                onClick={removeBanner}
                                type="button"
                                className="mt-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                            Remove Banner
                              </button>
                            ):null}
                          </div>
                        </div>
                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                          <button
                            onClick={saveGeneralSettings}
                            type="submit"
                            className="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-lg font-bold rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                        Save
                          </button>
                        </div>
                      </div>
                    </div>):(
                    <div className='my-36 items-center text-center'>
                      <p className='text-2xl font-normal text-center mb-6'>You need to connect your wallet:</p>
                      <button
                        onClick={openConnectWalletModal}
                        className='px-4 py-2 border border-transparent rounded-lg shadow-md text-base font-bold text-white bg-indigo-500 hover:bg-indigo-600 active:bg-indigo-700'
                      >Connect Wallet</button>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <ErrorModal
        isVisible={isErrorModalVisible}
        close={closeErrorModal}
        title={errorModalTitle}
        message={errorModalMessage}
      />

      <WalletModal
        isOpened={isConnectWalletModalVisible}
        setIsOpened={setIsConnectWalletModalVisible}
        chainId={80001}
        signingMessage="Connect your wallet."
        moralisAuth
      />

    </>
  );
};

export default SettingsModal;