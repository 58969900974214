import React, { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import PFP from "../Profile/PFP";
import { whiteButtonClass } from "../../helpers/styles";
import { CopyButton } from "@web3uikit/core";
import { getEllipsisTxt, WalletModal } from "@web3uikit/web3";
import Banner from "../Profile/Banner";
import SettingsModal from "../SettingsModal/SettingsModal";
import { getPolygonLogo } from "../../helpers/formatters";


const Top = () => {

  // DEFAULT:
  //  - https://img.freepik.com/premium-vector/purple-banner-templates-designed-web-various-headlines-vector-abstract-graphic-design-banner-pattern-background-template_181182-18343.jpg
  //  or
  //  - https://img.freepik.com/premium-vector/modern-purple-blue-white-wave-curve-3d-technology-banner-background-vector-abstract-graphic-design-banner-pattern-background-template_181182-18603.jpg
  const [banner, setBanner] = useState<string>("https://img.freepik.com/premium-vector/purple-banner-templates-designed-web-various-headlines-vector-abstract-graphic-design-banner-pattern-background-template_181182-18343.jpg");

  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState<boolean>(false);
  const openSettingsModal = () => setIsSettingsModalVisible(true);
  const closeSettingsModal = () => setIsSettingsModalVisible(false);

  const [isConnectWalletModalVisible, setIsConnectWalletModalVisible] = useState<boolean>(false);
  const openConnectWalletModal = () => setIsConnectWalletModalVisible(true);
  const closeConnectWalletModal = () => setIsConnectWalletModalVisible(false);

  const [userDetails, setUserDetails] = useState<any>();

  const { user, Moralis } = useMoralis();

  const getUserDetails = async() => {
    if(user) {
      try{
        const response: any = await Moralis.Cloud.run("getUserBalancesAndDomain", { address: user.get("ethAddress"), chain: "0xa869" });
        setUserDetails(response);
        console.log(response);
      }catch(err: any){
        console.log(err);
      }
    }
  };

  useEffect(() => {
    getUserDetails();
    if(user) {
      console.log(user.get("banner"));
      if(user.get("banner") != null
        || user.get("banner") != undefined
      ) {
        setBanner(user.get("banner"));
      }
    }
  }, [user]);

  const renderAddress = () => {
    if(userDetails) if(userDetails.domain) return `${userDetails.domain} (${getEllipsisTxt(user?.get("ethAddress"), 6)})`;
    else return `${getEllipsisTxt(user?.get("ethAddress"), 8)}`;
  };

  return(
    <>

      <div className="">
        <div className="">
          <Banner />
        </div>
        <div className="relative">
          <div className="absolute -top-32 left-52">
            {user ? (
              <PFP
                scale={24}
                stylesBlockie="rounded-full border-8 border-gray-100 transition-all duration-300"
                stylesImg="rounded-full border-8 border-gray-100 transition-all duration-300 w-52 h-52 bg-gray-100"
              />
            ):null}
          </div>
        </div>
      </div>

      <div className='flex flex-row justify-between'>
        <div className='flex flex-row pt-10 px-48'>
          {user ? (
            <div className="flex flex-row pt-10">
              <img
                src={getPolygonLogo()}
                className="rounded-full w-6 h-6 mr-1"
                alt="Polygon Logo"
              />
              <p className='text-gray-700 font-bold text-xl mr-3 cursor-pointer px-2 rounded-full'>
                {user.get("username")}
                <a href={`https://mumbai.polygonscan.com/address/${user?.get("ethAddress")}`} target="_blank" className="ml-3 hover:bg-gray-100 rounded-lg" rel="noreferrer">
                  <span className="text-gray-500 font-normal font-lg">{renderAddress()}</span>
                </a>
              </p>
              <div className="transform -translate-y-0">
                <CopyButton
                  text={user?.get("ethAddress")}
                  revertIn={6500}
                />
              </div>
            </div>
          ):(
            <div className="ml-10">
              <button
                onClick={openConnectWalletModal}
                className='px-4 py-2 border border-transparent rounded-lg shadow-md text-base font-bold text-white bg-indigo-500 hover:bg-indigo-600 active:bg-indigo-700'
              >Connect Wallet</button>
            </div>
          )}
        </div>
        <div className="py-12 px-16 flex flex-row justify-end">
          <button
            className={`${whiteButtonClass()} flex flex-row`}
            onClick={openSettingsModal}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <span className="align-middle">Settings</span>
          </button>
        </div>
      </div>

      <WalletModal
        isOpened={isConnectWalletModalVisible}
        setIsOpened={setIsConnectWalletModalVisible}
        chainId={80001}
        signingMessage="Connect your wallet."
        moralisAuth
      />

      <SettingsModal
        isVisible={isSettingsModalVisible}
        close={closeSettingsModal}
      /> 

    </>
  );
};

export default Top;