import React, { useState } from "react";
import { Tab } from "@headlessui/react";
import { Link } from "react-router-dom";

type TabType = "create"|"drafts"|"received";

interface TabsProps {
  tab: TabType,
}

const Tabs: React.FC<TabsProps> = ({ tab }) => {

  /*const handleTabClick = (_tab: string) => {
    if(_tab == "Create") {
      setTab("create");
    }else if(_tab == "Drafts") {
      setTab("drafts");
    }else{
      setTab("received");
    }
  };*/

  const [categories] = useState({
    Create: [],
    Drafts: [],
    Received: [],
  });

  return (
    <>
      <div className={"w-full bg-white sticky top-20 border-b-2 border-gray-200"}>
        <div className="w-full max-w-2xl px-24 pt-4 pb-4 sm:px-0">
          <Tab.Group>
            <Tab.List className="flex space-x-1 rounded-xl bg-indigo-900/20 p-1 bg-white ml-4">
              {Object.keys(categories).map((category) => (
                <Link
                  key={category}
                  to={`/app/${category.toLowerCase()}`}
                  className={"w-full"}
                >
                  <Tab
                    className={`w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-indigo-700 ring-white ring-opacity-60 ring-offset-2 ring-offset-indigo-400 focus:outline-none focus:ring-offset-2 hover:bg-indigo-50 ${tab==category.toLowerCase() ? "ring-4" : ""}`}
                  >
                    {category}
                  </Tab>
                </Link>
              ))}
            </Tab.List>
          </Tab.Group>
        </div>
      </div>
    </>
  );
};

export default Tabs;