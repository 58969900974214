import React from "react";
import { getPolygonLogo } from "../../helpers/formatters";
import { cardObj } from "../../helpers/interfaces";

interface DisplayCostsProps {
  card: cardObj
}


const DisplayCosts: React.FC<DisplayCostsProps> = ({ card }) => {

  const calculateAmount = (amount: number | undefined) => {
    const num = amount === undefined ? 0 : amount;
    return (num + (num *0.02))*Number(card.quantity);
  };

  return(
    <>
      <div className="w-full border-3 bg-gray-50 border-gray-300 hover:border-indigo-500 p-3 rounded-2xl transition-all duration-300 justify-between">
        <div className="flex flex-row mb-3">
          <p className="font-normal text-xl text-gray-900">{calculateAmount(card.value)}</p>
          <img
            src={getPolygonLogo()}
            alt="Token Logo"
            className="rounded-full h-7 w-7 ml-4 mr-2"
          />
          <p className="font-bold text-xl text-gray-900">MATIC</p>
        </div>
      </div>
      <p className="text-gray-600 font-normal mt-1 ml-1">*There is a 1% fee, paid by the sender (you).</p>
    </>
  );
};

export default DisplayCosts;