import { useNotification } from "@web3uikit/core";
import React, { useState } from "react";
import ErrorModal from "../../../components/Modals/ErrorModal";
import { useMoralis } from "react-moralis";
import { getNotification } from "../../../helpers/formatters";
import SendModal from "./SendModal";
import { purpleButtonClass } from "../../../helpers/styles";
import DisplayCard from "../../Card/DisplayCard";
import { cardObj } from "../../../helpers/interfaces";
import EditModal from "./EditModal";


interface SingleCardProps {
  card: cardObj,
  index: number
}

const SingleCard: React.FC<SingleCardProps> = ({ card, index }) => {

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
  const openDeleteModal = () => setIsDeleteModalVisible(true);
  const closeDeleteModal = () => setIsDeleteModalVisible(false);

  const [isSendModalVisible, setIsSendModalVisible] = useState<boolean>(false);
  const openSendModal = () => setIsSendModalVisible(true);
  const closeSendModal = () => setIsSendModalVisible(false);

  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const openEditModal = () => setIsEditModalVisible(true);
  const closeEditModal = () => setIsEditModalVisible(false);

  const { user, setUserData } = useMoralis();
  const notfiy = useNotification();

  const deleteCard = async() => {
    try{
      const cards: any = await user?.get("cards");
      cards.splice(index, 1);

      await setUserData({ cards: cards });

      closeDeleteModal();
      notfiy(getNotification("success", "Draft Deleted!", "Card has been deleted successfully! 😃"));
    }catch(err: any){
      notfiy(getNotification("error", "Something went wrong!", "Please try again or contact us! 😢"));
      console.log(err);
    }
  };

  return(
    <>
      <div className="flex flex-row w-full h-full max-h-64 border-3 bg-gray-50 border-gray-300 hover:border-indigo-500 p-3 rounded-2xl transition-all duration-300 mb-6 justify-between">
        <DisplayCard card={card} />
        <div className="flex flex-col justify-center">
          {/**buttons full purple or purple with outline*/}
          <button 
            className={purpleButtonClass()}
            onClick={openSendModal}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-3 my-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
            </svg>
          Send
          </button>
          <button 
            className={`${purpleButtonClass()} my-3`}
            onClick={openEditModal}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-3 my-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
            </svg>
          Edit
          </button>
          <button 
            className={purpleButtonClass()} 
            onClick={openDeleteModal}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-3 my-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
          Delete
          </button>
        </div>
      </div>

      <SendModal
        isVisible={isSendModalVisible}
        close={closeSendModal}
        card={card}
      />

      <EditModal
        isVisible={isEditModalVisible}
        close={closeEditModal}
        card={card}
        index={index}
      />

      <ErrorModal 
        isVisible={isDeleteModalVisible}
        close={closeDeleteModal}
        title="Delete Draft"
        message="Are you sure you want to delete this gift card draft. This action is irreversible."
        secondButton={
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={deleteCard}
          >
          Yes, delete
          </button>
        }
      />

    </>
  ); 
};

export default SingleCard;