import React, { useEffect } from "react";
import { useLaunch, Window } from "../../XMTP";

interface ChatBoxProps {
  signer: any,
  address: string
}

const ChatBox: React.FC<ChatBoxProps> = ({ signer, address }) => {

  const launch = useLaunch(signer);

  useEffect(() => {
    launch(address);
  }, []);

  return(
    <div>
      <Window />
    </div>
  );
};

export default ChatBox;