import React, { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { Link } from "react-router-dom";
import { cardObj } from "../../helpers/interfaces";
import { whiteButtonClass } from "../../helpers/styles";
import SingleCard from "./components/SingleCard";


const Drafts = () => {

  const [giftCards, setGiftCards] = useState<cardObj[] | null>([]);

  const { user } = useMoralis();

  useEffect(() => {
    (async function getCards() {
      try{
        if(user) {
          const cards: cardObj[] | undefined = user.get("cards");
          if(cards === undefined) {
            setGiftCards([]); // user doesn't have any
          }else{
            setGiftCards(cards);
          }
        }
      }catch(err: any){
        setGiftCards(null);
      }
    })();
  }, [user]);

  return(
    <>
      <div className="w-screen max-w-8xl ml-auto mr-auto my-12">
        <div className="mx-8">
          <p className="text-5xl font-extrabold">Drafts</p>
          <p className="text-lg font-normal text-gray-400 mt-1">Collection of your saved gift cards, so you don&apos;t have to create them again.</p>
          <div className="mt-6 mb-8">
            {giftCards?.map((card: cardObj, index: number) => {
              return(
                <SingleCard
                  key={index}
                  card={card}
                  index={index}
                />
              );
            })}
            {giftCards?.length == 0 ? (
              <div className="text-center my-20">
                <p className="font-light text-xl text-gray-700">You don&apos;t have any drafts yet. Please create one <span className="text-indigo-600 font-normal"><Link to="/create">here</Link></span>.</p>
              </div>
            ):null}
            {giftCards===null ? (
              <div className="text-center my-20">
                <p className="font-light text-xl text-gray-700">Error occurred while getting your drafts. Please refresh the website and try again or <span className="text-indigo-600 font-normal">contact support team</span>.</p>
              </div>
            ):null}
          </div>
          <div className="flex items-center justify-center">
            <Link to="/app/create">
              <button className={`${whiteButtonClass()} flex flex-row text-xl`}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                </svg>
                <span className="ml-2 mt-1 text-center">
                  Create New
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Drafts;