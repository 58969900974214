type IPosition = "topR" | "topL" | "bottomR" | "bottomL";

type notifyType = "error" | "info" | "success" | "warning";

type NotificationType = {
  type: notifyType,
  title: string,
  message: string,
  icon: undefined,
  position: IPosition
}

export const getNotification = (type: notifyType, title: string, message: string) => {
  const toReturn: NotificationType = {
    type: type,
    title: title,
    message: message,
    icon: undefined,
    position: "topL"
  };
  return toReturn;
};

export const getCoverImage = () => "https://ipfs.moralis.io:2053/ipfs/QmRaz5roQjavFWVEJJs1q6e4M5JrJfDP5B5Ag7YYr4HEn4";

export const formatBigNumber = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getPolygonLogo = () => "https://seeklogo.com/images/P/polygon-matic-logo-1DFDA3A3A8-seeklogo.com.png";

export const getName = () => "Gift Card";

export const getDescription = () => "description";

export const getExternalUrl = () => "https://tijan.dev/";

export const getMumbaiChainId = () => 80001;

