import React, { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";


interface BannerProps {
  stylesDefault?: string,
  stylesBanner?: string,
}


const Banner: React.FC<BannerProps> = ({ stylesDefault, stylesBanner }) => {

  const { user } = useMoralis();

  const [bannerImage, setBannerImage] = useState<string>("https://img.freepik.com/premium-vector/purple-banner-templates-designed-web-various-headlines-vector-abstract-graphic-design-banner-pattern-background-template_181182-18343.jpg");

  useEffect(() => {
    (async function getBanner() {
      if(user) {
        const banner = user.get("banner");
        if(banner) setBannerImage(banner);
        else setBannerImage("https://img.freepik.com/premium-vector/purple-banner-templates-designed-web-various-headlines-vector-abstract-graphic-design-banner-pattern-background-template_181182-18343.jpg");
      }else{
        setBannerImage("https://img.freepik.com/premium-vector/purple-banner-templates-designed-web-various-headlines-vector-abstract-graphic-design-banner-pattern-background-template_181182-18343.jpg");
      }
    })();
  }, [user]);

  return(
    <img
      alt="Banner Image"
      src={bannerImage}
      className="w-screen h-64 object-cover"
    />
  );
};

export default Banner;