import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { cardObj } from "../../../helpers/interfaces";
import ImageInput from "../../Basic/ImageInput";
import Input from "../../Basic/Input";
import TextArea from "../../Basic/TextArea";
import { purpleButtonClass } from "../../../helpers/styles";
import { useNotification } from "@web3uikit/core";
import { getNotification } from "../../../helpers/formatters";
import { useMoralis } from "react-moralis";

interface EditModalProps {
  isVisible: boolean,
  close: () => void,
  card: cardObj,
  index: number
}

const EditModal: React.FC<EditModalProps> = ({ isVisible, close, card, index }) => {

  const [image, setImage] = useState<string>(card.image);
  const [title, setTitle] = useState<string>(card.title);
  const [message, setMessage] = useState<string>(card.message);
  const [quantity, setQuantity] = useState<string | number>(card.quantity);
  const [value, setValue] = useState<number | string>(card.value);

  const { user, refetchUserData, setUserData } = useMoralis();
  const notify = useNotification();

  const saveChanges = async() => {
    try{
      if(image.length==0 || title.length==0 || message.length==0 || quantity.toString().length==0 || Number(quantity)<=0 || value.toString().length==0 || Number(value)<=0) {
        notify(getNotification("error", "Error! 😥", "Please fill in all the fields!"));
        return;
      }
      if(!user) {
        notify(getNotification("error", "Error! 😥", "Please connect your wallet!"));
        return;
      }

      const cardsToSave = await user?.get("cards");
      const newCard: cardObj = {
        title: title,
        message: message,
        quantity: quantity.toString(),
        value: Number(value),
        image: image,
        sender: {
          name: user.get("username"),
          pfp: user.get("pfp"),
          address: user.get("address")
        }
      };
      cardsToSave[index] = newCard;

      await setUserData({ cards: cardsToSave });
      notify(getNotification("success", "Changed Successfully! 😀", "Changes were saved successfully!"));
      close();
    }catch(err: any){
      notify(getNotification("error", "Something Went Wrong! 😥", err.message));
      console.log(err.message);
    }
  };

  return(
    <>
      <Transition appear show={isVisible} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-7xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className='flex flex-row justify-between mb-6 mt-1 ml-1'>
                    <Dialog.Title
                      as="h1"
                      className="text-5xl font-extrabold leading-6 text-gray-900 mt-2 flex flex-row"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-16 w-16 -m-5 pl-4 mr-3">
                        <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                        <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                      </svg>
                      Edit Draft
                    </Dialog.Title>
                    <div onClick={close} className='cursor-pointer rounded-md hover:bg-gray-100 transition-all duration-300'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-9 w-9" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                    </div>
                  </div>
                  <div>
                    {/** CONTENT: */}
                    <ImageInput
                      image={image}
                      setImage={setImage}
                    />
                    <Input
                      value={title}
                      onChange={(e: any) => setTitle(e.target.value)}
                      className="mb-1"
                      label="Title"
                      placeholder="Happy Birthday!"
                      type="text"
                    />
                    <TextArea
                      value={message}
                      onChange={(e: any) => setMessage(e.target.value)}
                      className="mb-1"
                      label="Message"
                      placeholder="We wish you a ..."
                    />
                    <Input
                      value={quantity}
                      onChange={(e: any) => setQuantity(e.target.value)}
                      className="mb-2"
                      label="Quantity"
                      placeholder="1"
                      type="number"
                    />

                    <Input
                      placeholder="e. g. 100"
                      label="Amount"
                      onChange={(e: any) => setValue(e.target.value)}
                      value={value}
                      type="number"
                      className=""
                      prefix="MATIC"
                      prefixPosition="end"
                    />

                    <button
                      className={`${purpleButtonClass()} w-full rounded-lg text-xl mt-10`}
                      onClick={saveChanges}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6 w-6 mr-4">
                        <path fillRule="evenodd" d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z" clipRule="evenodd" />
                      </svg>
                      Save Changes
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default EditModal;