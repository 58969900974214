import React from "react";
import { WalletModal } from "@web3uikit/web3";
import { useState } from "react";

const NoUser = () => {

  const [isConnectModalVisible, setIsConnectModalVisible] = useState<boolean>(false);
  const openConnectModal = () => setIsConnectModalVisible(true);

  return(
    <>
      <div className='mt-20 items-center text-center'>
        <p className='text-3xl font-light text-center mb-6'>You need to <span className="font-normal">connect your wallet</span> to be able to use this dApp:</p>
        <button
          onClick={openConnectModal}
          className='px-4 py-2 border border-transparent rounded-lg shadow-md text-base font-bold text-white bg-indigo-500 hover:bg-indigo-600 active:bg-indigo-700'
        >
        Connect Wallet
        </button>
      </div>

      <WalletModal
        isOpened={isConnectModalVisible}
        setIsOpened={setIsConnectModalVisible}
        chainId={80001}
        signingMessage="Connect your wallet."
        moralisAuth
      />
    </>
  );
};

export default NoUser;