import React, { Fragment, useEffect, useState } from "react";
import { useMoralisCloudFunction, useWeb3Contract } from "react-moralis";
import SingleTemplate from "./SingleTemplate";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import Input from "../Basic/Input";
import { purpleButtonClass } from "../../helpers/styles";
import { Loading } from "@web3uikit/core";
import { Template, TemplateObj } from "../../helpers/interfaces";
import UploadModal from "./UploadModal";
import { getStoreAbi, getStoreAddress } from "../../helpers/contract";
import { createStorageClient } from "../../helpers/storage";

interface TemplatesModalProps {
  isVisible: boolean,
  close: () => void,
  select: (image: string) => void
}

const TemplatesModal: React.FC<TemplatesModalProps> = ({ isVisible, close, select }) => {

  const [isUploadModalVisible, setIsUploadModalVisible] = useState<boolean>(false);
  const openUploadModal = () => setIsUploadModalVisible(true);
  const closeUploadModal = () => setIsUploadModalVisible(false);

  const [templates, setTemplates] = useState<TemplateObj[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { error, data, isFetching } = useMoralisCloudFunction("getTemplates");

  const Contract = useWeb3Contract({
    contractAddress: getStoreAddress(),
    functionName: "getAllTemplates",
    abi: getStoreAbi(),
  });

  const getNoResults = () => {
    if(!isFetching) {
      const results: TemplateObj[] = [];
      templates.map((template: TemplateObj) => {
        if(template.metadataObj) {
          if(template.metadataObj?.title.toLowerCase().includes(searchTerm.toLowerCase())) results.push(template);
        }
      });
      if(results.length==0) {
        return(
          <div className="flex flex-col justify-center text-2xl text-center pt-6">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 mr-auto ml-auto my-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.182 16.318A4.486 4.486 0 0012.016 15a4.486 4.486 0 00-3.198 1.318M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z" />
            </svg>
            <p>Sorry!</p>
            <p className="text-xl font-light mt-1">We couldn&apos;t find anything that matches your search term.</p>
          </div>
        );
      }else{
        null;
      }
    }
  };

  const fetch1 = async() => {
    try{
      const data: any = await Contract.runContractFunction();
      let toReturn: any = [];
      if(data){
        if(data.length>0){
          toReturn = await formatTemplates(data);
        }
      }
      const a = await (Promise.all(
        await toReturn.map(async(template: any, index: number) => {
          const res = await fetch(template.metadata, {mode: "cors"});
          const metadataObj = await res.json();
          toReturn[index].metadataObj = metadataObj;
        })
      ));
      console.log(toReturn);
      if(toReturn){
        setTemplates(toReturn);
      }
    }catch(err: any){
      console.log(err);
    }
  };

  const templateKeys = {
    id: 0,
    metadata: 1,
    owner: 2,
    price: 3,
    timesSold: 4,
    upvotes: 5,
    downvotes: 6
  };

  const formatTemplates = async(allTemplates: any[]) => {
    const toReturn: any[] = [];
  
    allTemplates.map((template: any) => {
      const objToReturn = {
        id: Number(template[templateKeys.id]).toString(),
        metadata: template[templateKeys.metadata],
        owner: template[templateKeys.owner],
        price: Number(template[templateKeys.price]).toString(),
        timesSold: Number(template[templateKeys.timesSold]).toString(),
        upvotes: Number(template[templateKeys.upvotes]).toString(),
        downvotes: Number(template[templateKeys.downvotes]).toString()
      };
      toReturn.push(objToReturn);
    });
    return toReturn;
  };

  useEffect(() => {
    (async function getTemplates() {
      await fetch1();
    })();
  }, [isVisible]);

  return(
    <>
      <Transition appear show={isVisible} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-9xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className='flex flex-row justify-between mb-6 mt-1 ml-1'>
                    <Dialog.Title
                      as="h1"
                      className="text-5xl font-extrabold leading-6 text-gray-900 mt-2 flex flex-row"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-16 w-16 -m-5 pl-4 mr-3">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z" />
                      </svg>
                      Templates Store
                    </Dialog.Title>
                    <div onClick={close} className='cursor-pointer rounded-md hover:bg-gray-100 transition-all duration-300'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-9 w-9" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                    </div>
                  </div>
                  <div className="bg-white">
                    <div>
                      <div>
                        <Input
                          placeholder='Happy Birthday'
                          value={searchTerm}
                          onChange={(e: any) => setSearchTerm(e.target.value)}
                          label="Search Templates"
                          type='text'
                          className='mb-4 ml-2'
                        />
                        <div className="grid grid-cols-3 gap-4 max-h-600 overflow-x-hidden overflow-scroll purple-scrollbar1">
                          {templates ? (
                            templates
                              .filter((template: TemplateObj) => {
                                if(template.metadataObj) {
                                  if(template.metadataObj?.title.toLowerCase().includes(searchTerm.toLowerCase())) return template;
                                }
                              })
                              .map((template: TemplateObj, index: number) => {
                                return(
                                  <SingleTemplate
                                    key={index}
                                    template={template}
                                    select={select}
                                  />
                                );
                              })
                          ):null}
                        </div>
                        {getNoResults()}
                        {templates.length==0 ? (
                          <div className='flex items-center justify-center align-middle'>
                            <Loading
                              fontSize={15}
                              size={24}
                              spinnerColor="#5a67d8"
                              spinnerType="wave"
                              text="Loading..."
                            />
                          </div>):null}
                      </div>
                    </div>
                    <button
                      className={`${purpleButtonClass()} w-full rounded-lg text-xl mt-7`}
                      onClick={openUploadModal}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6 mr-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 6h.008v.008H6V6z" />
                      </svg>
                      Sell your own
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <UploadModal
        isVisible={isUploadModalVisible}
        close={closeUploadModal}
      />
    </>
  );
};

export default TemplatesModal;