/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { useMoralis } from "react-moralis";
import PFP from "../Profile/PFP";
import LogOutModal from "../Modals/LogOutModal";
import { WalletModal } from "@web3uikit/web3";
import SettingsModal from "../SettingsModal/SettingsModal";
import WalletDrawer from "./WalletDrawer";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import logo from "./../../images/text.png";

const Navbar = () => {

  const [isConnectModalVisible, setIsConnectModalVisible] = useState<boolean>(false);
  const openConnectModal = () => setIsConnectModalVisible(true);

  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState<boolean>(false);
  const openSettingsModal = () => setIsSettingsModalVisible(true);
  const closeSettingsModal = () => setIsSettingsModalVisible(false);

  const [isWalletDrawerVisible, setIsWalletDrawerVisible] = useState<boolean>(false);
  const openWalletDrawer = () => setIsWalletDrawerVisible(true);
  const closeWalletDrawer = () => setIsWalletDrawerVisible(false);

  const [isLogOutModalVisible, setIsLogOutModalVisible] = useState<boolean>(false);
  const openLogOutModal = () => setIsLogOutModalVisible(true);
  const closeLogOutModal = () => setIsLogOutModalVisible(false);

  const { user, authenticate } = useMoralis();

  return (
    <>
      <Popover className="relative bg-white sticky top-0 border-b-2 border-gray-200 h-20">
        <div className="w-full">
          <div className="flex justify-between items-center px-16 py-4 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="#">
                <span className="sr-only">Workflow</span>
                <img
                  className="h-12 w-auto"
                  src={logo}
                  alt="Logo"
                />
              </a>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Open menu</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
              </Popover.Button>
            </div>

            <a href="#" className='bg-white rounded-md inline-flex items-center font-semibold text-xl text-gray-500 hover:text-gray-800 focus:outline-none'>
              Home
            </a>

            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              {!user ? (
                <button
                  onClick={openConnectModal}
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-lg shadow-md text-base font-bold text-white bg-indigo-500 hover:bg-indigo-600 active:bg-indigo-700"
                >
                Connect Wallet
                </button>
              ):(
                <div className='flex flex-row'>
                  <div>
                    <PFP
                      scale={5.25}
                      stylesImg="rounded-full border-3 border-gray-400 transition-all duration-300 w-11 h-11 hover:border-gray-600 cursor-pointer"
                      stylesBlockie='rounded-full border-3 border-gray-400 transition-all duration-300 hover:border-gray-600 cursor-pointer'
                    />
                  </div>
                  <div
                    className="text-gray-500 ml-6 hover:text-gray-700 cursor-pointer"
                    onClick={openSettingsModal}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                  </div>
                  <div
                    onClick={openWalletDrawer}
                    className='text-gray-500 ml-8 text-md hover:text-gray-700 cursor-pointer'
                  >
                    <MdOutlineAccountBalanceWallet />
                  </div>
                  <div
                    className="text-gray-500 ml-8 hover:text-gray-700 cursor-pointer"
                    onClick={openLogOutModal}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                    </svg>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          
          <div className="py-6 px-5 space-y-6">
            <div className="grid grid-cols-2 gap-y-4 gap-x-8">
              <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                Home
              </a>
            </div>
            {!user ? (
              <div>
                <button
                  onClick={openConnectModal}
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                    Connect Wallet
                </button>
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                    What is a wallet?{" "}
                  <a href="#" className="text-indigo-600 hover:text-indigo-500">
                      Read More
                  </a>
                </p>
              </div>
            ):(
              <div className='flex flex-row justify-center'>
                <div>
                  <PFP
                    scale={5.25}
                    stylesImg="rounded-full border-3 border-gray-400 transition-all duration-300 w-11 h-11 hover:border-gray-600 cursor-pointer"
                    stylesBlockie='rounded-full border-3 border-gray-400 transition-all duration-300 hover:border-gray-600 cursor-pointer'
                  />
                </div>
                <div
                  className="text-gray-500 ml-8 hover:text-gray-700 cursor-pointer"
                  onClick={openSettingsModal}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                  </svg>
                </div>
                <div
                  onClick={openWalletDrawer}
                  className='text-gray-500 ml-8 text-md hover:text-gray-700 cursor-pointer'
                >
                  <MdOutlineAccountBalanceWallet />
                </div>
                <div
                  className="text-gray-500 ml-8 hover:text-gray-700 cursor-pointer"
                  onClick={openLogOutModal}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                  </svg>
                </div>
              </div>
            )}
          </div>
        </Transition>
      </Popover>

      <LogOutModal
        isVisible={isLogOutModalVisible}
        close={closeLogOutModal}
      />

      <WalletDrawer
        isVisible={isWalletDrawerVisible}
        close={closeWalletDrawer}
      />

      <WalletModal
        isOpened={isConnectModalVisible}
        setIsOpened={setIsConnectModalVisible}
        chainId={80001}
        signingMessage="Connect your wallet."
        moralisAuth
      />

      <SettingsModal
        isVisible={isSettingsModalVisible}
        close={closeSettingsModal}
      /> 

    </>
  );
};

export default Navbar;
