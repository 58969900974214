import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CardMetadataObject, cardObj } from "../../../helpers/interfaces";
import DisplayCard from "../../../components/Card/DisplayCard";
import DisplayCosts from "../../../components/Card/DisplayCosts";
import { purpleButtonClass } from "../../../helpers/styles";
import Input from "../../Basic/Input";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import { useMoralis, useWeb3Contract } from "react-moralis";
import { useNotification } from "@web3uikit/core";
import { getDescription, getExternalUrl, getName, getNotification } from "../../../helpers/formatters";
import { getCardsAbi, getCardsAddress } from "../../../helpers/contract";
import PendingModal from "../../../components/Modals/PendingModal";
import SuccessModal from "../../../components/Modals/SuccessModal";
import ErrorModal from "../../../components/Modals/ErrorModal";
import { createStorageClient, jsonFile, makeGatewayURL } from "../../../helpers/storage";
import SwitchComponent from "../../Basic/Switch";


interface SendModalProps {
  isVisible: boolean,
  close: any,
  card: cardObj,
}

const SendModal: React.FC<SendModalProps> = ({ isVisible, close, card }) => {

  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false);
  const openConfirmModal = () => setIsConfirmModalVisible(true);
  const closeConfirmModal = () => setIsConfirmModalVisible(false);

  const [isErrorModalVisible, setIsErrorModalVisible] = useState<boolean>(false);
  const openErrorModal = () => setIsErrorModalVisible(true);
  const closeErrorModal = () => setIsErrorModalVisible(false);
  const [errorMessage, setErrorMessage] = useState<string>("An Error ocurred, please try again or contact out support team.");

  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState<boolean>(false);
  const openSuccessModal = () => setIsSuccessModalVisible(true);
  const closeSuccessModal = () => setIsSuccessModalVisible(false);

  const [isPendingModalVisible, setIsPendingModalVisible] = useState<boolean>(false);
  const openPendingModal = () => setIsPendingModalVisible(true);
  const closePendingModal = () => setIsPendingModalVisible(false);
  const [pendingTitle, setPendingTitle] = useState<string>("");
  const [pendingMessage, setPendingMessage] = useState<string>("");
  const [pendingComponent, setPendingComponent] = useState<any>(null);

  const [recipientEmail, setRecipientEmail] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [dateToSend, setDateToSend] = useState<any>();
  const [isScheduled, setIsScheduled] = useState(false);

  const notify = useNotification();
  const { user, Moralis } = useMoralis();
  const ethers = Moralis.web3Library;
  const Contract = useWeb3Contract({
    contractAddress: getCardsAddress(),
    functionName: "mintCards",
    abi: getCardsAbi(),
    params: {
      tokenURI: "",
      amount: 1,
      funds: [],
      reciever: "0x0834CFdf2b36cE1CC1f8Ec33BaacCaE12F82d9c9"
    }
  });

  const create = async() => {
    try{
      closeConfirmModal();
      if(address.length!=42 || address.slice(0, 2)!=="0x") {
        notify(getNotification("error", "Error! 😢", "You must input receiver!"));
        return;
      }
      if(!user) {
        notify(getNotification("error", "Error! 😢", "You must connect your wallet!"));
        return;
      }
      await createGiftCard();
    }catch(err: any) {
      console.log(err);
    }
  };

  const handleSend = () => {
    if(address.length!=42 || address.slice(0, 2)!=="0x") {
      notify(getNotification("error", "Error! 😢", "You must input receiver!"));
      return;
    }else if(!user) {
      notify(getNotification("error", "Error! 😢", "You must connect your wallet!"));
      return;
    }else if(isScheduled){
      if(dateToSend) {
        const dateSelected = new Date(dateToSend).getTime();
        const dateCurrent = new Date().getTime();
        console.log(dateSelected);
        console.log(dateCurrent);
        if(dateCurrent >= dateSelected) {
          notify(getNotification("error", "Error! 😢", "You must input future date!"));
          return;
        }else{
          openConfirmModal();
        }
      }else{
        notify(getNotification("error", "Error! 😢", "You must input future date!"));
        return;
      }
    }else{
      openConfirmModal();
    }
  };

  const uploadMetadata = async() => {
    try{
      notify(getNotification("info", "Uploading! ⏳", "Uploading metadata, please wait...!"));
      const namePrefix = "CardsMetadata";
      const uploadName = [namePrefix, "NFT Image"].join("|");

      const obj: CardMetadataObject = {
        name: getName(),
        description: getDescription(),
        external_url: getExternalUrl(),
        image: card.image,
        attributes: [
          {
            trait_type: "Title",
            value: card.title
          },
          {
            trait_type: "Message",
            value: card.message
          },
          {
            trait_type: "SenderName",
            value: card.sender.name
          },
          {
            trait_type: "SenderPFP",
            value: card.sender.pfp
          },
          {
            trait_type: "SenderAddress",
            value: card.sender.address
          },
        ]
      };
      
      const metadataFile = jsonFile("metadata.json", obj);
      const web3storage = createStorageClient();
      const cid = await web3storage.put([metadataFile], {
        name: uploadName,
      });
    
      const metadataGatewayURL = makeGatewayURL(cid, "metadata.json");
      const metadataURI = `ipfs://${cid}/metadata.json`;
      notify(getNotification("success", "Success! 😀", "Your metadata was uploaded successfully!"));
      const toReturn = { cid, metadataGatewayURL, metadataURI };
      console.log(toReturn);
      return toReturn;
    }catch(err: any){
      throw new Error("Something went wrong while saving metadata!");
    }
  };

  const createGiftCard = async() => {
    try{
      setPendingTitle("Please Wait!");
      setPendingMessage("Uploading metadata . . . Please wait!");
      openPendingModal();

      const metadataUri = await uploadMetadata();
      setPendingMessage("Metadata uploaded!");
      const value = Moralis.Units.ETH(card.value+card.value*0.02);
      let sendAt = 0;

      if(isScheduled) {
        console.log(dateToSend.date);
        const today = new Date();
        const sendAtTime = new Date(dateToSend);
        const date = await Moralis.Web3API.native.getDateToBlock({
          chain: "mumbai",
          date: today.toString()
        });
        const seconds = Math.round((sendAtTime.getTime() - today.getTime()) / 1000);
        console.log(seconds);
        console.log(date, Number(date.timestamp)+seconds);
        sendAt = Number(date.timestamp)+seconds;
      }

      console.log(sendAt);

      const options = {
        contractAddress: getCardsAddress(),
        functionName: "mintCards",
        abi: getCardsAbi(),
        msgValue: value,
        params: {
          tokenURI: metadataUri.metadataGatewayURL,
          amount: card.quantity,
          receiver: address,
          sendTime: sendAt
        }
      };

      setPendingMessage("Please confirm transaction in your wallet . . .");
      const tx: any = await Contract.runContractFunction({ params: options });
      console.log(tx);

      setPendingMessage("Transaction pending . . . Please wait!");
      setPendingComponent(
        <div>
          <p className="text-lg text-gray-600">View Transaction Details: <a target="_blank" href={`https://mumbai.polygonscan.com/tx/${tx.hash}`} rel="noreferrer">here</a>.</p>
        </div>
      );
      let receipt: any;
      if(tx) {
        receipt = await tx.wait();
        console.log(receipt);
        setPendingMessage("Finishing a few things . . .");
        await Moralis.Cloud.run("finishSendingCard", {
          email: getReceiverEmail(),
          card: card
        });
      }

      notify(getNotification("success", "New Notification!", "Your gift cards was minted successfully! 😀"));
      close();
      closePendingModal();
      openSuccessModal();
    }catch(err: any){
      notify(getNotification("error", "Error! 😢", "Something went wrong while minting your gift cards!"));
      console.log(err);
      closePendingModal();
      closeSuccessModal();
      openErrorModal();
      setErrorMessage(err.message);
    }
  };

  const getReceiverEmail = () => {
    if(recipientEmail.length>0){
      return recipientEmail;
    }else{
      return null;
    }
  };

  useEffect(() => {
    if(Contract.error) {
      console.log(Contract.error);
      closePendingModal();
      closeSuccessModal();
      openErrorModal();
      setErrorMessage(Contract.error.message);
    }
  }, [Contract.error]);

  useEffect(() => {
    if(Contract.data) {
      console.log(Contract.data);
    }
  }, [Contract.data]);

  return(
    <>
      <Transition appear show={isVisible} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-5 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-7xl transform overflow-hidden rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all">
                  <div className='flex flex-row justify-between mb-6'>
                    <Dialog.Title
                      as="h1"
                      className="text-5xl font-extrabold leading-6 text-gray-900 mt-2"
                    >
                    Send Gift Card
                    </Dialog.Title>
                    <div onClick={close} className='cursor-pointer rounded-md hover:bg-gray-100 transition-all duration-300'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-9 w-9" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                    </div>
                  </div>
                  <div>
                    {/**CONTENT */}
                    <p className='font-bold text-xl mb-2 mt-12'>Content and funds that the recipient will receive:</p>
                    <div className="flex flex-row w-full h-full max-h-64 border-3 bg-gray-50 border-gray-300 hover:border-indigo-500 p-3 rounded-2xl transition-all duration-300 justify-between">
                      <DisplayCard card={card} />
                    </div>

                    <p className='font-bold text-xl mb-2 mt-7'>Your Cost:</p>
                    <DisplayCosts card={card} />

                    <p className='font-bold text-xl mb-2 mt-6'>Recipient:</p>
                    <Input
                      type="text"
                      className=''
                      value={address}
                      onChange={(e: any) => setAddress(e.target.value)}
                      label="Recipient's Address:"
                      placeholder='0x...'
                    />
                    
                    <div className='mt-3'></div>
                    <Input
                      type='email'
                      className=''
                      value={recipientEmail}
                      onChange={(e: any) => setRecipientEmail(e.target.value)}
                      label="Recipient's email:"
                      placeholder='steve@example.com'
                    />
                    <p className="text-gray-600 font-normal ml-1">We will notify them that they have received a gift card!</p>

                    <div className="flex flex-row">
                      <p className='font-bold text-xl mb-2 mt-7'>Schedule transfer:</p>
                      <div className="pt-8 pl-6">
                        <SwitchComponent enabled={isScheduled} setEnabled={setIsScheduled} />
                      </div>
                    </div>
                    {isScheduled ? (
                      <div>
                        <Input
                          className=""
                          value={dateToSend}
                          onChange={(e: any) => setDateToSend(e.target.value)}
                          label="Select Date and Time of the Transfer:"
                          type="datetime-local"
                        />
                        <p className="text-gray-600 font-normal ml-1">Gift Card will be automatically send to the receiver at selected time.</p>
                      </div>
                    ):null}

                    <button
                      className={`${purpleButtonClass()} w-full rounded-lg text-xl mt-10`}
                      onClick={handleSend}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                      </svg>
                    Send
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <ConfirmModal
        title="Confirm Card Creation"
        message={`Once you click confirm this action is irreversible - gift card and all it's value will be transferred to the recipient - so make sure the recipient is correct! Recipient: ${address}`}
        isVisible={isConfirmModalVisible}
        close={closeConfirmModal}
        confirmFunction={create}
      />

      <PendingModal
        isVisible={isPendingModalVisible}
        title={pendingTitle}
        message={pendingMessage}
        component={pendingComponent}
      />

      <SuccessModal
        isVisible={isSuccessModalVisible}
        close={closeSuccessModal}
        title="Success!"
        message='Your gift card was successfully minted and sent to your friend!'
      />

      <ErrorModal
        isVisible={isErrorModalVisible}
        close={closeErrorModal}
        title={"Something went wrong!"}
        message={errorMessage}
      />
    
    </>
  );
};

export default SendModal;