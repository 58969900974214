import { Badge, useNotification } from "@web3uikit/core";
import React, { useState } from "react";
import { getNotification } from "../../helpers/formatters";
import { Template, TemplateObj } from "../../helpers/interfaces";
import DetailsModal from "./DetailsModal";

interface SingleTemplateProps {
  template: TemplateObj,
  select: (image: string) => void
}

const SingleTemplate: React.FC<SingleTemplateProps> = ({ template, select }) => {
  const { image, title, description, price, creator } = template.metadataObj;

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const openDetailsModal = () => setIsDetailsModalOpen(true);
  const closeDetailsModal = () => setIsDetailsModalOpen(false);

  const notify = useNotification();

  const handleSelect = () => {
    if(Number(template.price) == 0) select(image);
    else {
      notify(getNotification("info", "New Notification!", "This template is not free, you need to purchase it!"));
      openDetailsModal();
    }
  };

  const displayPrice = () => {
    if(Number(template.price) == 0) return "FREE";
    else return `${price}_MATIC`;
  };

  return(
    <>
      <div
        className="cursor-pointer p-5 hover:bg-gray-100 rounded-2xl h-fit w-fit"
      >
        <div
          className="watermarked"
          onClick={handleSelect}
        >
          <img
            src={image}
            className="rounded-xl w-max h-max"
            alt={title}
          />
        </div>
        <div className="mt-1 mx-1 flex flex-row justify-between">
          <div className="flex" onClick={handleSelect}>
            <p
              className="font-bold text-lg text-gray-600 mr-4"
            >{title}</p>
            <div className="mt-1">
              <Badge text={displayPrice()} textVariant="caption12" />
            </div>
          </div>
          <p onClick={openDetailsModal} className="font-normal text-lg text-indigo-600 cursor-pointer hover:underline">More</p>
        </div>
      </div>

      <DetailsModal
        isVisible={isDetailsModalOpen}
        close={closeDetailsModal}
        template={template}
        select={select}
      />
    </>
  );
};

export default SingleTemplate;