import React, { useState } from "react";
import { useMoralis } from "react-moralis";
import Input from "../Basic/Input";
import TextArea from "../Basic/TextArea";
import { cardObj } from "../../helpers/interfaces";
import { useNotification } from "@web3uikit/core";
import { getNotification } from "../../helpers/formatters";
import { purpleButtonClass } from "../../helpers/styles";
import ImageInput from "../Basic/ImageInput";
import SendModal from "../Drafts/components/SendModal";


const Create = () => {

  const [isSendModalVisible, setIsSendModalVisible] = useState<boolean>(false);
  const openSendModal = () => {
    console.log("opening");
    const toReturn = check();
    if(!toReturn || !user) return;
    console.log("opening");
    setIsSendModalVisible(true);
  };
  const closeSendModal = () => setIsSendModalVisible(false);

  const [image, setImage] = useState<any>();
  const [title, setTitle] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [quantity, setQuantity] = useState<string | number>("");
  const [value, setValue] = useState<number | string>("");

  const { user, setUserData, refetchUserData, Moralis } = useMoralis();
  const notify = useNotification();

  const check = () => {
    if(!user) {
      notify(getNotification("error", "Important!", "You must connect your wallet to create gift cards! "));
      return false;
    }else if(title.length<1
      || message.length<1
      || quantity==0
      || image.length<10
      || value <= 0
    ) {
      notify(getNotification("error", "Important!", "Fill in all fields to create a gift card!"));
      return false;
    }else{
      return true;
    }
  };

  const getCard = () => {
    const card: cardObj = {
      title: title,
      message: message,
      quantity: quantity.toString(),
      value: Number(value), 
      image: image,
      sender: {
        pfp: user?.get("pfp"),
        name: user?.get("username"),
        address: user?.get("ethAddress")
      },
    };

    return card;
  };

  const saveToDrafts = async() => {
    try{
      console.log("saveing");
      const toReturn = check();
      if(!toReturn || !user) return;

      await refetchUserData();

      const card: cardObj = {
        title: title,
        message: message,
        quantity: quantity.toString(),
        value: Number(value), 
        image: image,
        sender: {
          pfp: user.get("pfp"),
          name: user.get("username"),
          address: user.get("ethAddress")
        },
      };

      let oldCards = await user.get("cards");
      if(oldCards == undefined) oldCards = [];
      oldCards.push(card);

      setUserData({
        cards: oldCards
      });

      notify(getNotification("success", "Gift Card Saved! 😃", "Your gift card has been created successfully. You can see it in Drafts!"));
    }catch(err){
      notify(getNotification("error", "Something went wrong! 😢", "Something went wrong while creating your gift card!"));
      console.log(err);
    }
  };

  return(
    <>
      <div className="w-screen max-w-7xl ml-auto mr-auto mt-12 mb-28">
        <div className="mx-8">
          <p className="text-5xl font-extrabold">Create Gift Cards</p>
          <p className="text-lg font-normal text-gray-400 mt-1">Create your own gift cards and send them to your friens!</p>
          <div className="my-6 flex flex-row w-full">
            <div className="w-2/6">
              <p className="block text-sm font-medium text-gray-700 mb-1">Image</p>
              <ImageInput
                image={image}
                setImage={setImage}
              />
            </div>
            <div className="w-4/6">
              <Input
                value={title}
                onChange={(e: any) => setTitle(e.target.value)}
                className="mb-1"
                label="Title"
                placeholder="Happy Birthday!"
                type="text"
              />
              <TextArea
                value={message}
                onChange={(e: any) => setMessage(e.target.value)}
                className="mb-1"
                label="Message"
                placeholder="We wish you a ..."
              />
              <Input
                value={quantity}
                onChange={(e: any) => setQuantity(e.target.value)}
                className="mb-2"
                label="Quantity"
                placeholder="1"
                type="number"
              />

              <Input
                placeholder="e. g. 100"
                label="Amount"
                onChange={(e: any) => setValue(e.target.value)}
                value={value}
                type="number"
                className=""
                prefix="MATIC"
                prefixPosition="end"
              />
            </div>
          </div>
          <div className="mr-auto ml-auto mt-24 text-center">
            <button onClick={saveToDrafts} className={`${purpleButtonClass()} mr-8 text-xl font-medium`}>Save to drafts</button>
            <button onClick={openSendModal} className={`${purpleButtonClass()} text-xl font-medium`}>Quick mint</button>
          </div>
        </div>
      </div>

      <SendModal
        isVisible={isSendModalVisible}
        close={closeSendModal}
        card={getCard()}
      /> 

    </>
  );
};

export default Create;