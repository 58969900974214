import React from "react";
import { useMoralis } from "react-moralis";
import Create from "../Create/Create";
import Drafts from "../Drafts/Drafts";
import NoUser from "../NoUser/NoUser";
import Received from "../Received/Received";


interface RenderTabsProps {
  tab: "create"|"drafts"|"received";
}

const RenderTabsContent: React.FC<RenderTabsProps> = ({ tab }) => {

  const { user } = useMoralis();

  return(
    <div className="-z-1">
      {user ? (
        <>
          {tab === "create" ? (
            <Create />
          ):(
            tab === "drafts" ? (
              <Drafts />
            ):(
              <Received />
            )
          )}
        </>
      ):(
        <NoUser />
      )}
    </div>
  );
};

export default RenderTabsContent;