import React, { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { receivedCardObj, responseCard } from "../../helpers/interfaces";
import ReceivedCard from "./components/Card";


const Received = () => {

  const [receivedCards, setReceivedCards] = useState<receivedCardObj | null | undefined>(null);
  const [signer, setSigner] = useState<any>();
  const { Moralis, user, setUserData, isWeb3EnableLoading, isWeb3Enabled } = useMoralis();

  const refresh = async() => {
    await getReceivedCards();
  };

  const getReceivedCards = async() => {
    try{
      if(user) {
        const response: receivedCardObj = await Moralis.Cloud.run("getReceivedCards");
        if(response !== undefined) {
          setReceivedCards(response);
        }
        console.log(response);
      }
    }catch(err: any){
      setReceivedCards(undefined);
      console.log(err);
    }
  };

  useEffect(() => {
    (async function get() {
      await getReceivedCards();

    })();
  }, []);

  useEffect(() => {
    (async function get() {
      await getReceivedCards();
    })();
  }, [user]);

  return(
    <>
      <div className="w-screen max-w-8xl ml-auto mr-auto mt-12 mb-28">
        <div className="mx-8">
          <p className="text-5xl font-extrabold">Received Gift Cards</p>
          <p className="text-lg font-normal text-gray-400 mt-1">All gift cards you have received, here you can view and claim their value.</p>
          <div className="mt-6 mb-8">
            {receivedCards ? (
              receivedCards?.unclaimed.length==0 && receivedCards?.claimed.length==0 ? (
                <div className="text-center my-20">
                  <p className="font-light text-xl text-gray-700">You haven&apos;t received any gift cards yet. Tell your friend to send you one or send one to yourself to try it out!</p>
                </div>
              ):(
                <div>
                  <div className="mb-8">
                    <p className="text-3xl font-black mt-12 mb-6">Unclaimed Cards</p>
                    {receivedCards?.unclaimed.length==0 ? (
                      <div className="text-center my-20">
                        <p className="font-light text-xl text-gray-700">You don&apos;t have any unclaimed cards.</p>
                      </div>
                    ):(
                      <div className="grid grid-cols-4 gap-12">
                        {receivedCards.unclaimed.map((card: responseCard, index: number) => {
                          return(
                            <ReceivedCard
                              key={index}
                              card={card}
                              setClaimedFunction={refresh}
                            />
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div>
                    <p className="text-3xl font-black min-w-max mt-20 mb-6">Claimed Cards</p>
                    {receivedCards?.claimed.length==0 ? (
                      <div className="text-center my-20">
                        <p className="font-light text-xl text-gray-700">You don&apos;t have any claimed cards yet. Claim your unclaimed cards above!</p>
                      </div>
                    ):(
                      <div className="grid grid-cols-4 gap-12">
                        {receivedCards.claimed.map((card: responseCard, index: number) => {
                          return(
                            <ReceivedCard
                              key={index}
                              card={card}
                              setClaimedFunction={refresh}
                            />
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              )
            ):null}
            {receivedCards===null ? (
              <div className="text-center my-20">
                <p className="font-light text-xl text-gray-700">Loading... Please wait!</p>
              </div>
            ):null}
            {receivedCards===undefined ? (
              <div className="text-center my-20">
                <p className="font-light text-xl text-gray-700">Error occurred while fetching your received cards. Please refresh the website and try again or <span className="text-indigo-600 font-normal">contact support team</span>.</p>
              </div>
            ):null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Received;