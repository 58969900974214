import React from "react";
import Header from "./partials/Header";
import HeroHome from "./partials/HeroHome";
import FeaturesHome from "./partials/Features";
import FeaturesBlocks from "./partials/FeaturesBlocks";
import Footer from "./partials/Footer";
import Testimonials from "./partials/Testemonials";

function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header />
      <main className="flex-grow mb-24">
        <HeroHome />
        <FeaturesHome />
        <FeaturesBlocks />
        <Testimonials />
      </main>
      <Footer />
    </div>
  );
}

export default Home;